import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import defaultFotoProfil from "../../../asset/image/dashboard/fotorofil.jpeg";
import { FormLabel } from "../../../components/Dashboard/Form";
import Api from "../../../api/Api";
import { useModal } from "../../../lib/utils/hooks/useModal";
import { ModalGantiPassword } from "../../../components/Dashboard/profil/ModalGantiPasswordComponent";
import { ModalSuccess } from "../../../components/General/Alert/ModalSuccess";
import { ModalError } from "../../../components/General/Alert/ModalError";
import { API_ENDPOINT_DOCUMENT, API_ENDPOINT_PROFILE } from "../../../api/constants/api";

export const Profil = () => {
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataProfile, setDataProfile] = useState({
    nama: "",
    nip_nik: "",
    jabatan: "",
    email: "",
    no_hp: "",
    alamat: "",
    foto_profile: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/staff-admin/profile`);

      var newData = result;
      newData = {
        nama: result.data.name,
        nip_nik: result.data.nip_nik,
        jabatan: result.data.jabatan,
        email: result.data.email,
        no_hp: result.data.no_hp,
        alamat: result.data.alamat,
        foto_profile: result.data.foto_profile,
      };

      setDataProfile(newData);
    } catch (error) {
      console.log(error.mes);
      setError(error);
      setAlertMessage(error.message);
      setErrorModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitGantiPassword = async (rawData) => {
    try {
      setLoading(true);

      const result = await Api.postData(`/login/reset-password`, rawData);
      
      closeModalGantiPassword();
      setAlertMessage(result.message);
      setSuccessModalIsOpen(true);
    } catch (error) {
      setError(error);
      setAlertMessage(error.message);
      setErrorModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  }

  //VARIABLE FOR MODAL
  const {
    isOpen: modalGantiPasswordIsOpen,
    openModal: openModalGantiPassword,
    closeModal: closeModalGantiPassword,
  } = useModal();

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-4 items-center">
          <span className="text-sm sm:text-base md:text-xl font-bold text-black">
            Profil
          </span>
        </div>
      </div>
      <div className="flex flex-col mt-4 pb-4 rounded-lg bg-white">
        <div className="flex flex-row p-4 justify-between border-b-2 border-flinneutral-black4">
          <span className="text-sm sm:text-base md:text-xl font-bold text-black">
            Detail Akun User
          </span>
          <div className="flex flex-row gap-4">
            {/* HIDE, BUAT APA? */}
            {/* <button className="items-center text-xs md:text-sm bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinneutral-black7 px-5 py-2 border-2 border-flinneutral-black4">
              Reset
            </button> */}
            <button
              onClick={openModalGantiPassword}
              className="items-center text-xs md:text-sm bg-white hover:bg-flinprimary-bg hover:cursor-pointer rounded-lg md:rounded-xl text-flinneutral-black7 px-5 py-2 border">
              Ganti Password
            </button>
            <Link to="/profil/edit" state={dataProfile}>
              <button className="items-center text-xs md:text-sm bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2">
                Update
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col p-4">
          <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
            Foto Profil
          </label>
          <div className="flex flex-row items-center gap-5">
            <img
              src={(`${API_ENDPOINT_DOCUMENT}/${dataProfile.foto_profile}`) ?? defaultFotoProfil}
              className=" object-cover w-[80px] h-[80px] rounded-3xl border-2 border-flinneutral-black4 bg-flinprimary-normal"
            />
            {/* <div
              onClick={() => document.querySelector("#fotoProfil").click()}
              className="flex flex-row gap-2 md:gap-4 text-xs sm:text-sm md:text-base text-flinneutral-black7 border-2 border-flinneutral-black4 py-1.5 px-4 rounded-lg items-center hover:bg-flinneutral-black2 cursor-pointer">
              <FaPencil className="text-base" />
              <span>Ganti Foto</span>
              <input
                type="file"
                accept="image/*"
                id="fotoProfil"
                hidden
                onChange={({ target: { files } }) => {
                  if (files) {
                    // setDataProfile(foto_profile = URL.createObjectURL(files[0]));
                  }
                }}
              />
            </div> */}
          </div>
          <FormLabel labelName="Nama Staf" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="text"
            value={dataProfile.nama}
            disabled
            required
          />
          <FormLabel labelName="NIP/NIK" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="number"
            value={dataProfile.nip_nik}
            disabled
            required
          />
          <FormLabel labelName="Jabatan" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="text"
            value={dataProfile.jabatan}
            disabled
            required
          />
          <FormLabel labelName="Email" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="text"
            value={dataProfile.email}
            disabled
            required
          />
          <FormLabel labelName="No HP" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="number"
            value={dataProfile.no_hp}
            disabled
            placeholder="masukan kategori barang ..."
            required
          />
          <FormLabel labelName="Alamat" required={true} />
          <input
            className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
            type="text"
            value={dataProfile.alamat}
            disabled
            required
          />
        </div>
      </div>
      <ModalGantiPassword
        isOpen={modalGantiPasswordIsOpen}
        onClose={closeModalGantiPassword}
        onSubmit={onSubmitGantiPassword}
      />

      <ModalSuccess
        isOpen={successModalIsOpen}
        onClose={closeSuccessModal}
        message={alertMessage}
      />
      <ModalError
        isOpen={errorModalIsOpen}
        onClose={closeErrorModal}
        message={alertMessage}
      />
    </>
  );
};
