import React, { useState, useEffect } from "react";
import {
  FaTrashCan,
  FaPencil,
  FaChevronLeft,
  FaChevronRight,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { HeaderSubDashboardModal } from "../../../components/Dashboard/HeaderSubDashboard";
import { ModalTambahComponent } from "../../../components/Dashboard/data-staf/ModalTambahComponent";
import { ModalHapusComponent } from "../../../components/Dashboard/data-staf/ModalHapusComponent";
import { ModalEditComponent } from "../../../components/Dashboard/data-staf/ModalEditComponent";

import { columns } from "./columns";
import { useModal } from "../../../lib/utils/hooks/useModal";

import Api from "../../../api/Api";

export const DataStaf = () => {
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedData, setSelectedData] = useState([]);

  const [showFloating, setShowFloating] = useState(false);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/staff?page=${page}`);
      setData(result.data.data);
      setDetailData(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    if (error) {
      setShowFloating(true);
      const timeoutId = setTimeout(() => {
        setShowFloating(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [error]);

  const addStaff = async (result) => {
    try {
      setLoading(true);

      await Api.postData('/staff', result);

      fetchData();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      closeModalTambah();
    }
  };

  const deleteStaff = async (kode) => {
    try {
      setLoading(true);
      const requestData = { id: kode };

      await Api.deleteData('/staff', requestData);

      fetchData(detailData.current_page);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      closeModalHapus()
    }
  };

  const updateStaff = async (result) => {
    try {
      setLoading(true);

      await Api.updateData('/staff', result);

      fetchData(detailData.current_page);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      closeModalEdit();
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });
  //VARIABLE FOR MODAL
  const {
    isOpen: modalEditIsOpen,
    openModal: openModalEdit,
    closeModal: closeModalEdit,
  } = useModal();
  const {
    isOpen: modalTambahIsOpen,
    openModal: openModalTambah,
    closeModal: closeModalTambah,
  } = useModal();
  const {
    isOpen: modalHapusIsOpen,
    openModal: openModalHapus,
    closeModal: closeModalHapus,
  } = useModal();

  return (
    <>
      {/* HEADER */}
      <HeaderSubDashboardModal
        title="Data Staf"
        totalData={detailData.total}
        onAddClick={openModalTambah}
      />
      {/* END HEADER */}

      {/* TABLE */}
      <div className="mt-4 flex flex-col border border-flinneutral-black5 rounded-lg bg-white">
        <div className="grid grid-cols-1">
          {/* HEADER */}
          <div className="flex flex-row justify-between items-end md:items-center px-3 md:px-6 py-4">
            {/* PAGE SIZE */}
            <div className="flex items-center flex-col md:flex-row gap-3">
              <span className="text-base">Menampilkan</span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                  // fetchData(Number(e.target.value))
                }}
                className="rounded-lg p-2 border w-24 text-sm text-flinneutral-black7">
                {[5, 10, 20, 30, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    {pageSize} Data
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:3/5 md:w-2/6 lg:3/6">
              <div className="relative">
                <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 right-3" />
                <input
                  type="text"
                  value={filtering}
                  onChange={(e) => setFiltering(e.target.value)}
                  className="border border-flinneutral-black5 rounded-lg pr-10 px-3 py-2 text-sm w-full text-flinneutral-black7"
                  placeholder="Cari Staf ..."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 overflow-auto ">
          <table className="w-full">
            <thead className=" bg-flinneutral-black3 border-t border-b border-flinneutral-black5">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "⬆️", desc: "⬇️" }[
                        header.column.getIsSorted() ?? null
                        ]
                      }
                    </th>
                  ))}
                  <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left w-28 ">
                    Aksi
                  </th>
                </tr>
              ))}
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center h-12">
                  <td colSpan={columns.length + 1}>
                    Loading...
                  </td>
                </tr>
              ) : table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-3 md:px-6 py-3 text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                    <td className="flex flex-row gap-4 px-3 md:px-6 py-3 text-sm text-flinneutral-black7">
                      <FaPencil
                        onClick={() => {
                          setSelectedData(row.original);
                          openModalEdit();
                        }}
                        className=" cursor-pointer"
                      />
                      <FaTrashCan
                        onClick={() => {
                          setSelectedData(row.original);
                          openModalHapus();
                        }}
                        className=" cursor-pointer"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center h-12">
                  <td colSpan={12}>Tidak ada data staff</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-1 px-3 md:px-6 py-4">
          <div className="flex flex-col gap-2 md:flex-row items-center justify-center md:justify-between">
            {/* LEFT TABLE FOOTER */}
            <div className="flex flex-row gap-4">
              Halaman
              <strong>{detailData.current_page}</strong>
              <span>dari</span>
              <strong>{detailData.last_page}</strong>
            </div>
            {/* RIGHT TABLE FOOTER */}
            <div className="flex items-center">
              <button
                className={`mx-2 my-2 cursor-pointer ${(detailData.current_page > 1)
                  ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                  : "text-flinneutral-black7"
                  } `}
                disabled={!(detailData.current_page > 1)}
                onClick={() => fetchData(1)}>
                Halaman Pertama
              </button>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg  ${!(detailData.current_page > 1)
                  ? " bg-flinneutral-black4"
                  : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                  }`}
                disabled={!(detailData.current_page > 1)}
                onClick={() => fetchData(detailData.current_page - 1)}>
                <FaChevronLeft />
              </button>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg ${!(detailData.current_page < detailData.last_page)
                  ? "bg-flinneutral-black4"
                  : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                  }`}
                disabled={!(detailData.current_page < detailData.last_page)}
                onClick={() => fetchData(detailData.current_page + 1)}>
                <FaChevronRight />
              </button>
              <button
                className={`mx-2 my-2 cursor-pointer ${(detailData.current_page < detailData.last_page)
                  ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                  : "text-flinneutral-black7"
                  } `}
                disabled={!(detailData.current_page < detailData.last_page)}
                onClick={() => fetchData(detailData.last_page)}>
                Halaman terakhir
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Error Notification */}
      {showFloating && (
        <div className="fixed top-8 right-4 w-72 p-2 bg-red-600 rounded-xl flex items-center gap-2">
          <div className="flex-grow h-5 text-white text-base leading-normal">
            {error.message}
          </div>
        </div>
      )}


      {/* END TABLE */}

      <ModalTambahComponent
        isOpen={modalTambahIsOpen}
        onClose={closeModalTambah}
        onSubmit={addStaff}
      />
      <ModalEditComponent
        isOpen={modalEditIsOpen}
        onClose={closeModalEdit}
        data={selectedData}
        onSubmit={updateStaff} />
      <ModalHapusComponent
        isOpen={modalHapusIsOpen}
        onClose={closeModalHapus}
        data={selectedData}
        onDelete={deleteStaff}
      />


    </>
  );
};
