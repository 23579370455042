import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaCircleCheck, FaXmark } from "react-icons/fa6";
export const ModalError = ({ isOpen, onClose, message }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [onClose]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="fixed top-4 right-4 bg-flindanger-normal text-white rounded-md p-4 shadow-md transition-all duration-300">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 md:gap-4">
            <FaCircleCheck className="text-2xl" />
            <span className="text-sm">{message}</span>
          </div>
          <button onClick={() => onClose()}>
            <FaXmark className="ml-4 text-sm" />
          </button>
        </div>
      </div>
    </Transition>
  );
};
