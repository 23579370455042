export const getFormatterDate = () => {
  const today = new Date();
  return `${today.getDate()} 
  ${today.toLocaleString("default", { month: "long" })} ${today.getFullYear()}`;
};

export const formattedDateToIndonesia = (date = "") => {
  const day = new Date(date).toLocaleDateString("default", {
    day: "numeric",
  });
  const month = new Date(date).toLocaleDateString("default", {
    month: "long",
  });
  const year = new Date(date).toLocaleDateString("default", {
    year: "numeric",
  });
  return `${day} ${month} ${year}`;
}