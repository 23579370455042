export function getOrderStatus(status) {
  switch (status) {
    case 1:
      return (
        <span className="bg-green-100 text-green-600 font-semibold text-[10px] md:text-xs p-1.5 px-2 md:px-4 rounded-full">
          Selesai
        </span>
      );
    case 0:
      return (
        <span className="bg-blue-100 text-blue-600 font-semibold text-[10px] md:text-xs p-1.5 px-2 md:px-4 rounded-full">
          Aktif
        </span>
      );
    default:
      return (
        <span className="bg-blue-100 text-blue-600 font-semibold text-[10px] md:text-xs p-1.5 px-2 md:px-4 rounded-full">
          Aktif
        </span>
      );
  }
}
