import React, { useState, useEffect } from "react";
import LogoFlin from "../../../asset/icon/logo-flindigital.png";
import ImageHero from "../../../asset/image/auth/hero-image-1.jpg";
import LogoKantor from "../../../asset/icon/logo-kantor.png";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineLockClosed } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";

const SignIn = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailNip, setEmailNip] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = { email_nip: emailNip, password: password };

      const result = await Api.postData("/login", data);

      localStorage.setItem("token", result.data.token);
      localStorage.setItem("name", result.data.nama);
      localStorage.setItem("role", result.data.role);
      localStorage.setItem("foto_profile", result.data.foto_profile);
      navigate("/pengguna-manfaat");
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAutoLogin = () => {
    const token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/pengguna-manfaat";
      return;
    }
  };
  const handleToglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="h-screen w-full md:w-1/2 lg:w-2/3 flex flex-col p-4 md:p-6 lg:p-8">
        <div className=" h-4/5">
          <div className="relative h-5/6">
            <img
              src={ImageHero}
              alt=""
              className="absolute inset-0 w-full h-full object-cover rounded-xl z-0"
            />
            <div className="relative z-10 bg-white w-5/6 sm:w-4/6 md:w-3/5 lg:w-2/5 flex flex-row p-2 px-4 rounded-b-3xl gap-2 mx-auto">
              <div className="grid content-center">
                <img
                  src={LogoKantor}
                  alt=""
                  className=" max-w-[40px] sm:max-w-[50] md:max-w-[60] object-containt"
                />
              </div>
              <p className="p-2 text-xs sm:text-sm font-bold text-[#0270af]">
                SEKRETARIAT DPRD KABUPATEN MURUNG RAYA
              </p>
            </div>
          </div>
          <div className="h-1/6 flex flex-row justify-center pt-8 sm:p-6 md:py-5 gap-2">
            <div className="p-1 rounded-full bg-[#0270af] w-6 h-2 md:w-12 md:h-3"></div>
            <div className="p-1 rounded-full bg-gray-300 w-2 h-2 md:w-3 md:h-3"></div>
            <div className="p-1 rounded-full bg-gray-300 w-2 h-2 md:w-3 md:h-3"></div>
          </div>
        </div>
        <div className="h-1/5 pb-2 md:pb-4">
          <div className="border border-gray-300 p-4 rounded-xl bg-[#FCFCFC] gap-2 flex flex-col">
            <h4 className=" text-sm md:text-lg lg:text-xl font-bold text-[#014369]">
              Siap Bisa
            </h4>
            <p className=" text-sm md:text-base lg:text-lg text-[#014369]">
              Optimalkan penggunaan inventaris dengan pemantauan real-time serta
              pencatatan yang lebih efisien
            </p>
          </div>
        </div>
      </div>
      <div className=" bg-[#0270af] flex flex-col justify-center px-4 md:px-6 lg:px-8 w-full md:w-1/2 lg:w-1/3 h-screen">
        <div className="w-full content-between grid grid-cols-1 bg-white shadow-sm rounded-xl px-4 md:px-6 lg:px-8 py-12 sm:py-10">
          {/* HEADER FORM */}
          <div className="flex flex-col text-center sm:text-left">
            <span className="text-center text-lg md:text-3xl font-semibold md:font-extrabold text-flinprimary-normal">
              SIAP BISA
            </span>
            <p className="mb-5 text-sm md:text-lg md:font-semibold text-flinprimary-normal">
              Sistem Aplikasi Barang Inventaris Setwan
            </p>
            <h5 className=" font-semibold text-lg md:text-2xl">Login</h5>
            <p className="text-[#94A3B8] text-xs md:text-sm lg:text-base">
              Selamat Datang di Aplikasi Siap Bisa
            </p>
          </div>

          {/* FORM */}
          <form className="mx-auto w-full my-8 sm:my-4" onSubmit={login}>
            <div className="flex flex-col my-1 lg:my-2">
              <label className="text-xs md:text-sm my-1 lg:my-2">
                Email/Nip
              </label>
              <div className="relative">
                <AiOutlineUser className="w-5 h-5 text-gray-500 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                <input
                  required
                  onChange={(e) => {
                    setEmailNip(e.target.value);
                  }}
                  className="border border-gray-300 pl-10 pr-3 py-2 rounded-xl w-full text-xs md:text-sm"
                  type="text"
                  placeholder="Masukkan Email/NIP"
                />
              </div>
            </div>
            <div className="flex flex-col my-1 lg:my-2">
              <label className="text-xs md:text-sm my-1 lg:my-2">
                Password
              </label>
              <div className="relative">
                <HiOutlineLockClosed className="w-5 h-5 text-gray-500 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                <input
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Masukkan Password"
                  className="border border-gray-300 pl-10 pr-3 py-2 rounded-xl w-full text-xs md:text-sm"
                  type={showPassword ? "text" : "password"}
                />
                {showPassword ? (
                  <FaEye
                    onClick={handleToglePassword}
                    className="cursor-pointer w-5 h-5 text-xl text-gray-500 z-10 absolute top-1/2 transform -translate-y-1/2 right-3"
                  />
                ) : (
                  <FaEyeSlash
                    onClick={handleToglePassword}
                    className="cursor-pointer w-5 h-5 text-xl text-gray-500 z-10 absolute top-1/2 transform -translate-y-1/2 right-3"
                  />
                )}
              </div>
            </div>
            {error ? (
              <div className="flex flex-row p-2 rounded-lg bg-flindanger-light my-2 md:my-4">
                <span className="text-flindanger-normal text-[10px] md:text-[12px] ">
                  {error.message}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className=" mt-8 md:mt-10 mb-2">
              <button
                type="submit"
                className="text-xs md:text-sm bg-[#0270af] w-full py-2.5 rounded-xl text-white hover:bg-[#02659e]">
                {loading ? "Loading..." : "Masuk"}
              </button>
            </div>
            <div className="text-center text-xs md:text-sm my-2 lg:my-4">
              <span className="text-[#94A3B8]">Ada masalah saat masuk ? </span>
              <a className="text-[#0270af] font-bold cursor-pointer hover:text-[#02659e]">
                Hubungi Developer
              </a>
            </div>
          </form>
          <div className="flex flex-col text-center gap-2">
            <span className=" text-xs text-[#94A3B8]">Developed By</span>
            <img
              src={LogoFlin}
              alt=""
              className=" w-20 sm:24 md:w-32 mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
