import React, { Fragment, useEffect, useState } from "react";
import {
  FaXmark,
  FaMagnifyingGlass,
  FaTrashCan,
  FaPencil,
  FaChevronLeft,
  FaChevronRight,
  FaEye,
} from "react-icons/fa6";
import { columns } from "./columns";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { HeaderSubDashboardLink } from "../../../components/Dashboard/HeaderSubDashboard";
import { Dialog, Transition } from "@headlessui/react";
import iconTandaSeru from "../../../asset/icon/icon-tanda-seru.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import { ModalSuccess } from "../../../components/General/Alert/ModalSuccess";
import { ModalError } from "../../../components/General/Alert/ModalError";

export const DataBarang = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [selectedData, setSelectedData] = useState({
    nama: "",
  });

  const [loading, setLoading] = useState([]);
  const [error, setError] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  let [modalIsOpen, modalSetIsOpen] = useState(false);
  // ALERT
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/barang?page=${page}`);
      setData(result.data.data);
      setDetailData(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedBarang = async () => {
    try {
      setLoading(true);
      const requestData = { id_barang: selectedData.kode };

      await Api.deleteData("/barang", requestData);
      // Display success alert
      setAlertMessage("Barang berhasil dihapus");
      setSuccessModalIsOpen(true);
      fetchData();
    } catch (error) {
      setError(error);
      // Display error alert
      setAlertMessage("Gagal menghapus barang");
      setErrorModalIsOpen(true);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    fetchData();

    if (location.state && location.state.successMessage) {
      console.log("ok");
      // Display success notification
      setAlertMessage(location.state.successMessage);
      setSuccessModalIsOpen(true);
    } else if (location.state && location.state.errorMessage) {
      // Display error notification
      setAlertMessage(location.state.errorMessage);
      setErrorModalIsOpen(true);
    }

    // Clear the state parameters
    navigate(location.pathname, { replace: true });
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  function closeModal() {
    modalSetIsOpen(false);
  }
  function openModal() {
    modalSetIsOpen(true);
  }

  return (
    <>
      <HeaderSubDashboardLink
        title="Data Barang"
        totalData={detailData.total}
        onLinkView="tambah"
      />
      {/* TABLE */}
      <div className="mt-4 flex flex-col border border-flinneutral-black5 rounded-lg bg-white">
        {/* TABLE HEADER */}
        <div className="grid grid-cols-1">
          <div className="flex flex-row justify-between items-end md:items-center px-3 md:px-6 py-4">
            {/* PAGE SIZE */}
            <div className="flex items-center flex-col md:flex-row gap-3">
              <span className="text-base">Menampilkan</span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="rounded-lg p-2 border w-24 text-sm text-flinneutral-black7">
                {[5, 10, 20, 30, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    {pageSize} Data
                  </option>
                ))}
              </select>
            </div>
            {/* SEARCH */}
            <div className="sm:3/5 md:w-2/6 lg:3/6">
              <div className="relative">
                <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 right-3" />
                <input
                  type="text"
                  value={filtering}
                  onChange={(e) => setFiltering(e.target.value)}
                  className="border border-flinneutral-black5 rounded-lg pr-10 px-3 py-2 text-sm w-full text-flinneutral-black7"
                  placeholder="Cari Barang ..."
                />
              </div>
            </div>
          </div>
        </div>
        {/* END TABLE HEADER */}

        <div className="grid grid-cols-1 overflow-auto ">
          <table className="w-full">
            <thead className=" bg-flinneutral-black3 border-t border-b border-flinneutral-black5">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "⬆️", desc: "⬇️" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </th>
                  ))}
                  <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left w-28 ">
                    Aksi
                  </th>
                </tr>
              ))}
            </thead>

            <tbody>
              {loading ? (
                <tr className="text-center h-12">
                  <td colSpan={columns.length + 1}>Loading...</td>
                </tr>
              ) : table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-3 md:px-6 py-3 text-sm">
                        {flexRender(cell.getValue, cell.getContext())}
                      </td>
                    ))}
                    <td className="flex flex-row gap-4 px-3 md:px-6 py-3 text-sm text-flinneutral-black7">
                      <Link to="detail" state={data[row.id]}>
                        <FaEye />
                      </Link>
                      <Link to="edit" state={data[row.id]}>
                        <FaPencil />
                      </Link>
                      <FaTrashCan
                        onClick={() => {
                          setSelectedData(data[row.id]);
                          openModal();
                        }}
                        className=" cursor-pointer"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center h-12">
                  <td colSpan={12}>Tidak ada data barang</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-1 px-3 md:px-6 py-4">
          <div className="flex flex-col gap-2 md:flex-row items-center justify-center md:justify-between">
            {/* LEFT TABLE FOOTER */}
            <div className="flex flex-row gap-4">
              Halaman
              <strong>{detailData.current_page}</strong>
              <span>dari</span>
              <strong>{detailData.last_page}</strong>
            </div>
            {/* RIGHT TABLE FOOTER */}
            <div className="flex items-center">
              <span
                className={`mx-2 my-2 cursor-pointer ${
                  detailData.current_page > 1
                    ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                    : "text-flinneutral-black7"
                } `}
                disabled={!(detailData.current_page > 1)}
                onClick={() => fetchData(1)}>
                Halaman Pertama
              </span>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg  ${
                  !(detailData.current_page > 1)
                    ? " bg-flinneutral-black4"
                    : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                }`}
                disabled={!(detailData.current_page > 1)}
                onClick={() => fetchData(detailData.current_page - 1)}>
                <FaChevronLeft />
              </button>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg ${
                  !(detailData.current_page < detailData.last_page)
                    ? "bg-flinneutral-black4"
                    : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                }`}
                disabled={!(detailData.current_page < detailData.last_page)}
                onClick={() => fetchData(detailData.current_page + 1)}>
                <FaChevronRight />
              </button>
              <button
                className={`mx-2 my-2 cursor-pointer ${
                  detailData.current_page < detailData.last_page
                    ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                    : "text-flinneutral-black7"
                } `}
                disabled={!(detailData.current_page < detailData.last_page)}
                onClick={() => fetchData(detailData.last_page)}>
                Halaman terakhir
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END TABLE */}

      {/* MODAL DELETE BARANG */}
      <Transition appear show={modalIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xs md:max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col">
                    <img
                      src={iconTandaSeru}
                      alt=""
                      className="max-w-[100px] mx-auto my-2"
                    />
                    <span className="text-center my-2 text-base md:text-lg font-semibold">
                      Hapus Data "{selectedData.nama}" ?
                    </span>
                    <span className="text-center mb-6 text-sm md:text-base">
                      Apakah anda yakin untuk menghapus data kategori Barang
                      Bergerak secara permanen "{selectedData.nama}"?
                    </span>
                    <div className="flex flex-row gap-4">
                      <button
                        type="button"
                        onClick={closeModal}
                        className="flex-1 items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                        Batalkan
                      </button>
                      <button
                        type="submit"
                        onClick={deleteSelectedBarang}
                        className="flex-1 items-center text-xs md:text-base bg-flindanger-normal hover:bg-flindanger-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
                        Hapus Data
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ModalSuccess
        isOpen={successModalIsOpen}
        onClose={() => setSuccessModalIsOpen(false)}
        message={alertMessage}
      />
      <ModalError
        isOpen={errorModalIsOpen}
        message={alertMessage}
        onClose={() => setErrorModalIsOpen(false)}
      />
    </>
  );
};
