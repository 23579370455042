import React from "react";
// import { FaDivide } from "react-icons/fa6";
import { HeaderSubDashboardModal } from "../../../components/Dashboard/HeaderSubDashboard";

export const Setting = () => {

  const handleUpdateClick = () => {
  };

  return (
    <>
      <HeaderSubDashboardModal
        title="Setting"
      />
      {/* TABLE */}
      <div className="mt-4 flex flex-col border-flinneutral-black5 rounded-lg bg-white">
        <div className="grid grid-cols-1">
          {/* HEADER */}
          <div className="flex flex-row justify-between items-end px-3 py-4">
            <span className="font-inter sm:text-base md:text-xl font-bold text-black leading-tight">
              Login Page
            </span>
          </div>
          <hr />
          <div className="m-6 flex flex-col border border-flinneutral-black5 rounded-lg bg-white">
            <div className="flex flex-row justify-between items-end px-3 py-4">
              <div className="flex flex-row gap-3 items-center h-full">
                <span className="font-inter text-xs md:text-base font-semibold text-black">Data Instansi</span>
              </div>
              <button
                type="button"
                onClick={handleUpdateClick}
                className="flex flex-row gap-3 items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover cursor-pointer rounded-lg md:rounded-xl text-white px-2 md:px-4 py-2 md:py-3 h-full"
              >
                Update
              </button>
            </div>
            <hr/>
            <div>te</div>
          </div>
        </div>
      </div>
    </>
  );
};
