import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import iconTandaSeru from "../../../asset/icon/icon-tanda-seru.svg";
export const ModalHapusComponent = ({ isOpen, onClose, onDelete, data }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xs md:max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col">
                  <img
                    src={iconTandaSeru}
                    alt=""
                    className="max-w-[100px] mx-auto my-2"
                  />
                  <span className="text-center my-2 text-base md:text-lg font-semibold">
                    Hapus Data "{data.nama_lengkap}" ?
                  </span>
                  <span className="text-center mb-6 text-sm md:text-base">
                    Apakah anda yakin untuk menghapus data "{data.nama_lengkap}" secara
                    permanen ?{" "}
                  </span>
                  <div className="flex flex-row gap-4">
                    <button
                      type="button"
                      onClick={onClose}
                      className="flex-1 items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                      Batalkan
                    </button>
                    <button
                      type="submit"
                      onClick={() => onDelete(data.kode)}
                      className="flex-1 items-center text-xs md:text-base bg-flindanger-normal hover:bg-flindanger-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
                      Hapus Data
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
