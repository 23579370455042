// REALEASE
export const API_ENDPOINT = "https://api.siapbisa.flindigital.com/api";
export const API_ENDPOINT_DOCUMENT =
  "https://api.siapbisa.flindigital.com/storage";

// DEVELOPMENT
// export const API_ENDPOINT = "https://dev.api.siapbisa.flindigital.com/api";
// export const API_ENDPOINT_DOCUMENT =
//   "https://dev.api.siapbisa.flindigital.com/storage";

// LOCAL
// export const API_ENDPOINT = 'http://127.0.0.1:8000/api'
// export const API_ENDPOINT_DOCUMENT = 'http://127.0.0.1:8000/storage'
