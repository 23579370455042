import React, { useState, useEffect } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { FormLabel } from "../../../../components/Dashboard/Form";
import {
  MyRadioGroup,
  MyRadioGroupStatus,
} from "../../../../components/General/Form/MyRadioGroup";
import { FaMagnifyingGlass, FaSquarePlus, FaTrashCan } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { ModalHapusComponent } from "../../../../components/Dashboard/pengguna-manfaat/ModalHapusComponent";
import Api from "../../../../api/Api";
import { ModalSuccess } from "../../../../components/General/Alert/ModalSuccess";
import { ModalError } from "../../../../components/General/Alert/ModalError";

export const EditPenggunaManfaat = () => {
  const params = useLocation().state;
  const navigate = useNavigate();

  const [jenisStaf, setJenisStaf] = useState("nonstaff");
  const [namaStaf, setNamaStaf] = useState("");
  const [nikStaf, setNikStaf] = useState("");
  // NONSTAFF
  const [namaNonStaff, setNamaNonStaff] = useState("");
  const [nikNonStaff, setNikNonStaff] = useState("");

  const [loading, setLoading] = useState(false);

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  // const [beritaAcara, setBeritaAcara] = useState(null);
  // const [fileNameBeritaAcara, setFileNameBeritaAcara] = useState(
  //   "DefaultFileName.pdf"
  // );
  // const [sizeFile, setSizeFile] = useState("(0.20 MB)");
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const radioOptionsStap = [
    { value: "staff", label: "Staff" },
    { value: "nonstaff", label: "Non Staff" },
  ];
  const radioOptionsStatusBarang = [
    { value: false, label: "Aktif" },
    { value: true, label: "Selesai" },
  ];

  const [barang, setBarang] = useState([]);
  // const [maxId, setmaxId] = useState(1);
  // const addBarang = () => {
  //   const newId = maxId + 1;
  //   setmaxId(newId);
  //   setBarang([
  //     ...barang,
  //     {
  //       id: newId,
  //       namaBarangKodeBarang: "",
  //       tanggalMulai: "",
  //       tanggalAkhir: "",
  //       status: "",
  //     },
  //   ]);
  // };
  // const removeBarang = (index) => {
  //   setDeleteItemIndex(index);
  //   setIsDeleteModalOpen(true);
  // };
  // const handleDeleteItem = (index) => {
  //   setBarang((prevBarang) => {
  //     const updatedBarang = [...prevBarang];
  //     updatedBarang.splice(index, 1);
  //     return updatedBarang;
  //   });
  //   setIsDeleteModalOpen(false);
  // };
  const resetForm = () => {
    setJenisStaf("");
    setNamaStaf("");
    // setBarang([
    //   {
    //     id: 1,
    //     namaBarangKodeBarang: "",
    //     tanggalMulai: "",
    //     tanggalAkhir: "",
    //     status: "false",
    //   },
    // ]);
    // setmaxId(1);
    // setBeritaAcara(null);
    // setFileNameBeritaAcara("");
    // setSizeFile("");
  };

  const handleBarangChange = (index, fieldName, value) => {
    const updatedBarang = [...barang];
    updatedBarang[index][fieldName] = value;
    setBarang(updatedBarang);
  };

  useEffect(() => {
    if (params) {
      if (params.staff ?? false) {
        setNamaStaf(params.staff.nama_lengkap);
        setNikStaf(params.staff.nip_nik);
        setJenisStaf("staff");
      } else {
        setNamaNonStaff(params.nama_nonstaff);
        setNikNonStaff(params.nik_nonstaff);
        setJenisStaf("nonstaff");
      }

      const newItems = params.borrowed.map((borrowedItem) => {
        return {
          id: borrowedItem.id,
          namaBarang: borrowedItem.itemcode.item.nama,
          kodeBarang: borrowedItem.itemcode.kode_barang,
          tanggalMulai: borrowedItem.tanggal_mulai,
          tanggalAkhir: borrowedItem.tanggal_akhir,
          status: borrowedItem.status_pinjam == 0 ? false : true,
        };
      });
      setBarang(newItems);
    }
  }, [params]);

  const updateStatusPenggunaManfaat = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const rawData = barang.map((item) => {
        return {
          id: item.id,
          status: item.status,
        };
      });

      await Api.updateData("/penggunaan-barang/updatenew", rawData);

      navigate("/pengguna-manfaat", {
        state: {
          successMassage: "Pengguna manfaat berhasil ditambahkan",
        },
      });
    } catch (e) {
      navigate("/pengguna-manfaat", {
        state: {
          errorMessage: "Gagal menambahkan pengguna manfaat",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <HeaderBreadcrumb
          pathName="Data Pengguna Manfaat"
          pathLink="/pengguna-manfaat"
          pageName="Edit Pengguna Manfaat"
        />
        <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
          {/* HEADER */}
          <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
            <span className="text-sm sm:text-base md:text-lg text-black">
              Edit Pengguna Manfaat
            </span>
          </div>

          {/* FORM CONTENT */}
          <form action="" onSubmit={updateStatusPenggunaManfaat}>
            <div className="flex flex-col px-2 sm:px-4 md:px-6 pb-4">
              <div className="flex flex-col md:flex-row md:gap-4">
                {/* NAMA BARANG */}
                <div className="flex flex-col flex-1">
                  <FormLabel
                    labelName="Jenis"
                    // required={true}
                  />
                  <MyRadioGroup
                    disabled={true}
                    value={jenisStaf}
                    onChange={setJenisStaf}
                    options={radioOptionsStap}
                  />
                </div>
              </div>

              {jenisStaf == "staff" ? (
                <>
                  {/* NAMA STAFF */}
                  <div className="flex flex-col flex-1">
                    <FormLabel
                      labelName="Nama Staff"
                      // required={true}
                    />
                    <div className="flex-1 relative">
                      <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                      <select
                        className="text-xs sm:text-sm border-2 border-flinneutral-black4 pl-8 px-3 py-2.5 rounded-xl w-full"
                        type="text"
                        name="kategori-barang"
                        value={`${namaStaf} - ${nikStaf}`}
                        disabled
                        // onChange={(e) => setNamaStaf(e.target.value)}
                        placeholder="cari masukan nama staf">
                        <option value={`${namaStaf} - ${nikStaf}`} disabled>
                          {namaStaf} - {nikStaf}
                        </option>
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* NON STAFF */}
                  <div className="flex flex-col flex-1">
                    <FormLabel labelName="Nama Non-Staff" required={true} />
                    <input
                      className="text-xs sm:text-sm border-2 border-flinneutral-black4 px-3 py-2.5 rounded-xl w-full"
                      type="text"
                      disabled
                      value={`${namaNonStaff} - ${nikNonStaff}`}
                      required
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <FormLabel labelName="Nik Non-Staff" required={true} />
                    <input
                      className="text-xs sm:text-sm border-2 border-flinneutral-black4 px-3 py-2.5 rounded-xl w-full"
                      type="text"
                      disabled
                      value={nikNonStaff}
                      onChange={(e) => {
                        setNikNonStaff(e.target.value);
                      }}
                      required
                    />
                  </div>
                </>
              )}

              {/* BARANG */}
              <div className="flex flex-row items-center gap-2">
                <FormLabel labelName="Barang" required={true} />
                <span className="my-2 md:mt-4 text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                  {barang.length} Barang
                </span>
              </div>

              <div className="text-xs sm:text-sm flex flex-col gap-2 md:gap-4 my-2">
                {barang.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col rounded-lg border pb-2 md:pb-4 mb-4">
                    <div className="flex flex-row border-b justify-between items-center p-4">
                      <span className="font-semibold">Data Barang </span>
                      <div
                        style={{ display: "none" }}
                        // onClick={() => removeBarang(index)}
                        className="flex flex-row text-flindanger-normal rounded-xl items-center gap-2 border border-flindanger-normal py-1.5 px-3 hover:bg-flindanger-bg cursor-pointer">
                        <FaTrashCan />
                        <span className="text-sm">Hapus</span>
                      </div>
                    </div>
                    <div className="flex flex-col px-2 md:px-4">
                      <FormLabel
                        labelName="Nama Barang - Kode Barang"
                        // required={true}
                      />
                      <div className="flex-1 relative">
                        <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                        <select
                          className="text-xs sm:text-sm  border-2 border-flinneutral-black4 pl-8 px-3 py-2.5 rounded-xl w-full"
                          type="text"
                          disabled
                          value={`${item.namaBarang} - ${item.kodeBarang}`}
                          // value={item.namaBarangKodeBarang}
                          // onChange={(e) =>
                          //   handleBarangChange(
                          //     index,
                          //     "namaBarangKodeBarang",
                          //     e.target.value
                          //   )
                          // }
                          required>
                          <option
                            value={`${item.namaBarang} - ${item.kodeBarang}`}
                            disabled>
                            {item.namaBarang} - {item.kodeBarang}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-row px-2 md:px-4 gap-2 md:gap-4">
                      <div className="flex-1 flex flex-col">
                        <FormLabel
                          labelName="Tanggal Mulai"
                          //  required={true}
                        />
                        <input
                          className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                          type="date"
                          disabled
                          value={item.tanggalMulai}
                          // onChange={(e) =>
                          //   handleBarangChange(
                          //     index,
                          //     "tanggalMulai",
                          //     e.target.value
                          //   )
                          // }
                        />
                      </div>
                      <div className="flex-1 flex flex-col">
                        <FormLabel
                          labelName="Tanggal Akhir"
                          // required={true}
                        />
                        <input
                          className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                          type="date"
                          disabled
                          value={item.tanggalAkhir}
                          // onChange={(e) =>
                          //   handleBarangChange(
                          //     index,
                          //     "tanggalAkhir",
                          //     e.target.value
                          //   )
                          // }
                        />
                      </div>
                    </div>
                    <div className="flex flex-col px-2 md:px-4">
                      <FormLabel labelName="Status" required={true} />
                      <MyRadioGroupStatus
                        value={item.status}
                        onChange={(newValue) =>
                          handleBarangChange(index, "status", newValue)
                        }
                        options={radioOptionsStatusBarang}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* BUTTON ADD */}
              <div
                style={{ display: "none" }}
                // onClick={addBarang}
                className="flex flex-row items-center border-2 border-dashed border-flinprimary-normal p-2 rounded-xl gap-2 justify-center hover:bg-flinprimary-bg cursor-pointer text-flinprimary-normal">
                <FaSquarePlus className="text-xs md:text-sm" />
                <span className="text-xs md:text-sm font-normal">
                  Tambah Barang
                </span>
              </div>
              {/* ADD FILE */}
              {/* <div style={{ display: 'none' }} className="flex flex-col flex-1">
              <FormLabel labelName="Upload File Berita Acara" required={true} />
              <div
                onClick={() => document.querySelector("#pdfFile").click()}
                className="rounded-lg flex flex-col items-center border-2 border-dashed border-flinneutral-black5 p-4 md:p-6 cursor-pointer gap-2 md:gap-4">
                <FaFileArrowUp className="text-2xl md:text-4xl text-flinprimary-normal" />
                <span className="text-xs md:text-sm">Unggah File</span>

                <div
                  className={`w-full flex flex-row items-center gap-2 md:gap-4 p-2 md:p-4 border rounded-lg ${
                    fileNameBeritaAcara !== "" ? "" : "hidden"
                  }`}>
                  <FaFilePdf className="text-lg text-flinneutral-black7" />
                  <div className="flex flex-col flex-1 gap-0.5">
                    <span className="text-xs">{fileNameBeritaAcara}</span>
                    <span className="text-xs text-flinneutral-black7">
                      {sizeFile}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setBeritaAcara(null);
                      setFileNameBeritaAcara("");
                      setSizeFile("");
                    }}
                    className="flex p-1 rounded-full bg-flinneutral-black4 items-center hover:bg-flinneutral-black5 hover:cursor-pointer">
                    <FaXmark className="text-x" />
                  </div>
                </div>
                <input
                  type="file"
                  accept=".pdf"
                  id="pdfFile"
                  hidden
                  onChange={({ target: { files } }) => {
                    if (files && files[0]) {
                      setFileNameBeritaAcara(files[0].name);
                      setSizeFile(
                        `(${(files[0].size / (1024 * 1024)).toFixed(2)} MB)`
                      );
                      setBeritaAcara(files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'none' }} className="flex flex-row text-xs text-flinneutral-black7 my-2 justify-between">
              <span>Format file yang didukung : PDF</span>
              <span>Maksimal 5 MB</span>
            </div> */}
              {/* FOOTER FORM */}
              <div className="mt-5 border-t border-flinneutral-black4 flex flex-row pt-5 justify-between items-center">
                <span
                  onClick={resetForm}
                  className="text-sm md:text-base text-flinneutral-black7 cursor-pointer hover:text-flinneutral-black6">
                  Reset
                </span>
                <div className="flex flex-row items-center gap-4">
                  <Link to="/pengguna-manfaat">
                    <button
                      type="button"
                      className="items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                      Batalkan
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
                    {loading ? "Menyimpan..." : "Simpan"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <ModalHapusComponent
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => handleDeleteItem(deleteItemIndex)}
      /> */}
      </div>
      <ModalSuccess
        isOpen={successModalIsOpen}
        onClose={closeSuccessModal}
        message={alertMessage}
      />
      <ModalError
        isOpen={errorModalIsOpen}
        onClose={closeErrorModal}
        message={alertMessage}
      />
    </>
  );
};
