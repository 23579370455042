// RadioGroup.js
import React from "react";
import { RadioGroup } from "@headlessui/react";
import { FaRegCircle, FaRegCircleDot } from "react-icons/fa6";

export const MyRadioGroup = ({ value, onChange, options, disabled = false }) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className={`flex flex-row gap-2 md:gap-4 ${disabled ? 'opacity-50' : ''}`}>
        {options.map((option) => (
          <RadioGroup.Option
            disabled={disabled}
            key={option.value}
            value={option.value}
            className={({ checked }) =>
              `${
                checked ? "border-flinprimary-normal border-2" : "border"
              } flex-1 flex flex-row rounded-lg  px-2 py-2 cursor-pointer`
            }>
            {({ checked }) => (
              <div className="flex flex-row gap-2 items-center">
                {checked ? (
                  <FaRegCircleDot className="text-flinprimary-normal" />
                ) : (
                  <FaRegCircle className="text-flinneutral-black7" />
                )}
                <span className="text-flinneutral-black7 text-xs sm:text-sm">
                  {option.label}
                </span>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export const MyRadioGroupStatus = ({ value, onChange, options }) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className="flex flex-row gap-2 md:gap-4">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ checked }) =>
              `${
                checked ? "border-flinprimary-normal border-2" : "border"
              } flex-1 flex flex-row rounded-lg  px-2 py-2 cursor-pointer`
            }>
            {({ checked }) => (
              <div className="flex flex-row gap-2 items-center">
                {checked ? (
                  <FaRegCircleDot className="text-flinprimary-normal text-lg md:text-xl" />
                ) : (
                  <FaRegCircle className="text-flinneutral-black7 text-lg md:text-xl" />
                )}
                {option.value === true ? (
                  <span className="bg-green-100 text-green-600 font-semibold text-[10px] md:text-xs p-1 px-2 md:px-4 rounded-full">
                    Selesai
                  </span>
                ) : (
                  <span className="bg-blue-100 text-blue-600 font-semibold text-[10px] md:text-xs p-1 px-2 md:px-4 rounded-full">
                    Aktif
                  </span>
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
