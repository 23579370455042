import React, { useState } from "react";
import { Sidebar } from "./sidebar/Sidebar";
import { Navbar } from "./navbar/Navbar";
import { Outlet } from "react-router-dom";
import { Footer } from "./footer/Footer";

export const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="flex bg-flinprimary-bg h-screen">
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />
      <div className="flex-1 flex flex-col justify-between">
        <Navbar onToggleSidebar={toggleSidebar} />
        {/* ========CONTENT======= */}
        <div className="p-6 flex-1 overflow-scroll flex-col">{<Outlet />}</div>
        {/* ========END CONTENT======= */}
        {/* FOOTER DEKSTOP */}
        <div className="hidden md:flex">
          <Footer />
        </div>
      </div>
    </div>
  );
};
