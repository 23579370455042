import React, { useEffect, useState } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { FormLabel } from "../../../../components/Dashboard/Form";
import { FaSquarePlus, FaTrashCan } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from "../../../../api/Api";

export const EditBarang = () => {
  const navigate = useNavigate();

  const params = useLocation().state;

  const [selectedKategori, setSelectedKategori] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [kodeBarang, setKodeBarang] = useState([]);

  const [dataKategori, setDataKategori] = useState([]);

  const [maxId, setMaxId] = useState(1);

  const [selectedAll, setSelectedAll] = useState(false);
  const [totalChecked, setTotalChecked] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (params) {
      setNamaBarang(params.nama);
      setSelectedKategori(params.category_name);

      const newList = (params.item_code || []).map((item, index) => ({
        real_id: item.id,
        id: index + 1,
        check: false,
        kode: item.kode_barang,
      }));

      setKodeBarang(newList);
    }
  }, [params]);

  useEffect(() => {
    const checkedItems = kodeBarang.filter((item) => item.checked);
    setTotalChecked(checkedItems.length);
  }, [kodeBarang]);

  const fetchDataKategori = async () => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/kategori`);
      setDataKategori(result.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateBarangPost = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const kategoriKode = dataKategori.find(
        (item) => item.nama == selectedKategori
      );
      const newList = kodeBarang.map((item) => ({
        id: item.real_id ?? null,
        kode: item.kode,
      }));

      const rawData = {
        id_barang: params.kode,
        nama_barang: namaBarang,
        kategori_barang: kategoriKode.kode,
        kode_unik_barang: newList,
      };

      await Api.updateData("/barang", rawData);
      //redirect and send data
      navigate("/data-barang", {
        state: {
          successMessage: "Barang berhasil diedit",
        },
      });
    } catch (error) {
      setError(error);
      navigate("/data-barang", {
        state: {
          errorMessage: "Barang gagal diedit",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataKategori();
  }, []);

  const addKodeBarang = () => {
    const newId = kodeBarang.length + 1;
    setMaxId(newId);
    setKodeBarang((prev) => [...prev, { id: newId, kode: "", checked: false }]);
  };
  const removeKodeBarang = (id) => {
    setKodeBarang((section) => section.filter((section) => section.id !== id));
  };

  const handleSelectAll = () => {
    const updatedKodeBarang = kodeBarang.map((item) => ({
      ...item,
      checked: !selectedAll,
    }));
    setKodeBarang(updatedKodeBarang);
    setSelectedAll(!selectedAll);
  };
  const removeAllKodeBarang = () => {
    const filteredKodeBarang = kodeBarang.filter((item) => !item.checked);
    setKodeBarang(filteredKodeBarang);
    setSelectedAll(false);
  };
  return (
    <div>
      <HeaderBreadcrumb
        pathName="Data Barang"
        pathLink="/data-barang"
        pageName="Edit Barang"
      />
      <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
        {/* HEADER */}
        <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
          <span className="text-sm sm:text-base md:text-lg text-black">
            Edit Barang
          </span>
        </div>

        {/* FORM CONTENT */}
        <form action="" onSubmit={(e) => updateBarangPost(e)}>
          <div className="flex flex-col px-2 sm:px-4 md:px-6 pb-4">
            <div className="flex flex-col md:flex-row md:gap-4">
              {/* NAMA BARANG */}
              <div className="flex flex-col flex-1">
                <FormLabel labelName="Nama Barang" required={true} />
                <input
                  className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                  type="text"
                  name="nama-barang"
                  value={namaBarang}
                  onChange={(e) => setNamaBarang(e.target.value)}
                  placeholder="masukan nama barang ..."
                  required
                />
              </div>
              <div className="flex flex-col flex-1">
                {/* KATEGORI BARANG */}
                <FormLabel labelName="Kategori Barang" required={true} />
                <select
                  className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2.5 rounded-xl w-full"
                  type="text"
                  name="kategori-barang"
                  value={selectedKategori}
                  onChange={(e) => {
                    setSelectedKategori(e.target.value);
                  }}
                  placeholder="masukan kategori barang ..."
                  required>
                  <option value="" disabled>
                    Pilih Kategori
                  </option>
                  {dataKategori.map((kategori) => (
                    <option key={kategori.kode} value={kategori.nama}>
                      {kategori.nama}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* KODE BARANG */}
            <div className="flex flex-row items-center gap-2 justify-between my-2">
              <div className="flex flex-row items-center gap-2">
                <input
                  type="checkbox"
                  id="selectSemuaKode"
                  name="selectSemuaKode"
                  className="md:mt-2 mr-2"
                  checked={selectedAll}
                  onChange={handleSelectAll}
                />
                <FormLabel labelName="Kode Barang" required={true} />
                <span className="my-2 md:mt-4 text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                  {kodeBarang.length} Barang
                </span>
              </div>
              <div
                onClick={removeAllKodeBarang}
                className={`md:mt-2 flex flex-row text-white rounded-xl items-center gap-2  py-2 px-3 bg-flindanger-normal hover:bg-flindanger-normalHover cursor-pointer ${
                  totalChecked == 0 ? "hidden" : ""
                }`}>
                <FaTrashCan />
                <span className="text-sm">
                  Hapus Barang terpilih {`(${totalChecked})`}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2 md:gap-4">
              {kodeBarang.map((section) => (
                <div
                  key={section.id}
                  className="flex flex-row gap-2 md:gap-4 items-center">
                  <input
                    type="checkbox"
                    id={`select${section.id}`}
                    value={section.kode}
                    name="selectSemuaKode"
                    checked={section.checked}
                    onChange={() => {
                      const updatedKodeBarang = kodeBarang.map((item) =>
                        item.id === section.id
                          ? { ...item, checked: !item.checked }
                          : item
                      );
                      setKodeBarang(updatedKodeBarang);
                    }}
                  />
                  <input
                    className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                    type="text"
                    name={`kode-barang${section.id}`}
                    value={section.kode}
                    onChange={(e) => {
                      const updatedKodeBarang = kodeBarang.map((item) =>
                        item.id === section.id
                          ? { ...item, kode: e.target.value }
                          : item
                      );
                      setKodeBarang(updatedKodeBarang);
                    }}
                    placeholder="masukkan kode barang..."
                    required
                  />
                  <div
                    onClick={() => removeKodeBarang(section.id)}
                    className="flex flex-row text-flindanger-normal rounded-xl items-center gap-2 border border-flindanger-normal py-1.5 px-3 hover:bg-flindanger-bg cursor-pointer">
                    <FaTrashCan />
                    <span className="text-sm">Hapus</span>
                  </div>
                </div>
              ))}
              <div
                onClick={addKodeBarang}
                className="flex flex-row items-center border-2 border-dashed border-flinprimary-normal p-2 rounded-xl gap-2 justify-center hover:bg-flinprimary-bg cursor-pointer text-flinprimary-normal">
                <FaSquarePlus className="text-xs md:text-sm" />
                <span className="text-xs md:text-sm font-normal">
                  Tambah Kode Barang
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 border-t border-flinneutral-black4 flex flex-row justify-end pt-5 gap-4 px-2 sm:px-4 md:px-6">
            <Link to="/data-barang">
              <button
                type="button"
                className="items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                Batalkan
              </button>
            </Link>
            <button
              type="submit"
              className="items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
              {loading ? "Meyimpan" : "Simpan"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
