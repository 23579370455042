import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./pages/auth/SignIn/SignIn";
import {
  Layout,
  PenggunaManfaat,
  RiwayatPeminjaman,
  DataBarang,
  DataKategori,
  DataStaf,
  Setting,
} from "./lib/imports/DashboardImport";
import { DetailBarang } from "./pages/dashboard/data-barang/detail/DetailBarang";
import { TambahBarang } from "./pages/dashboard/data-barang/detail/TambahBarang";
import { EditBarang } from "./pages/dashboard/data-barang/detail/EditBarang";
import { Profil } from "./pages/dashboard/profil/Profil";
import { EditProfil } from "./pages/dashboard/profil/EditProfil";
import { DetailPenggunaManfaat } from "./pages/dashboard/pengguna-manfaat/detail/DetailPenggunaManfaat";
import { TambahPenggunaManfaat } from "./pages/dashboard/pengguna-manfaat/detail/TambahPenggunaManfaat";
import { EditPenggunaManfaat } from "./pages/dashboard/pengguna-manfaat/detail/EditPenggunaManfaat";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="pengguna-manfaat" element={<PenggunaManfaat />} />
          <Route
            index
            path="pengguna-manfaat/detail"
            element={<DetailPenggunaManfaat />}
          />
          <Route
            index
            path="pengguna-manfaat/tambah"
            element={<TambahPenggunaManfaat />}
          />
          <Route
            index
            path="pengguna-manfaat/edit"
            element={<EditPenggunaManfaat />}
          />

          <Route path="riwayat-peminjaman" element={<RiwayatPeminjaman />} />

          {/* ROUTE DATA BARANG */}
          <Route path="data-barang" element={<DataBarang />} />
          <Route path="data-barang/detail" element={<DetailBarang />} />
          <Route path="data-barang/tambah" element={<TambahBarang />} />
          <Route path="data-barang/edit" element={<EditBarang />} />

          <Route path="data-kategori" element={<DataKategori />} />
          <Route path="data-staf" element={<DataStaf />} />
          <Route path="setting" element={<Setting />} />

          {/* ROUTE PROFIL */}
          <Route path="profil" element={<Profil />} />
          <Route path="profil/edit" element={<EditProfil />} />
        </Route>
        <Route path="login" element={<SignIn />} />
        <Route path="data" element={<DataBarang />} />
      </Routes>
    </Router>
  );
}

export default App;
