import { HiNewspaper } from "react-icons/hi";
import {
  FaClockRotateLeft,
  FaRegNewspaper,
  FaBoxOpen,
  FaBoxesPacking,
  FaUsersLine,
  FaGear,
} from "react-icons/fa6";

export const DASHBOARD_SIDEBAR_LINK_UTAMA = [
  {
    key: "pengguna manfaat",
    label: "pengguna manfaat",
    path: "/pengguna-manfaat",
    icon: <FaRegNewspaper />,
  },
  // {
  //   key: "riwayat peminjam",
  //   label: "riwayat peminjam",
  //   path: "/riwayat-peminjaman",
  //   icon: <FaClockRotateLeft />,
  // },
  {
    key: "data barang",
    label: "data barang",
    path: "/data-barang",
    icon: <FaBoxOpen />,
  },
  {
    key: "data kategori",
    label: "data kategori",
    path: "/data-kategori",
    icon: <FaBoxesPacking />,
  },
  {
    key: "data staf",
    label: "data staf",
    path: "/data-staf",
    icon: <FaUsersLine />,
  },
];

export const DASHBOARD_SIDEBAR_LINK_SETTING = [
  {
    key: "setting",
    label: "setting",
    path: "/setting",
    icon: <FaGear />,
  },
];
