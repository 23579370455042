import { getOrderStatus } from "../../../lib/utils/helpers/getOrderStatus";
export const columns = [
  {
    accessorKey: "name",
    header: "Nama",
  },
  {
    accessorKey: "namaBarang",
    header: "Daftar Barang",
  },
  {
    accessorKey: "statusBarang",
    header: "Status",
    cell: (props) => getOrderStatus(props.getValue()),
  },
];
