import React from "react";
import { FaCopyright } from "react-icons/fa6";

export const Footer = () => {
  return (
    <div className="flex flex-row w-full h-16 bg-white p-6 items-center text-flinneutral-black7 text-xs border-t border-flinneutral-black4 ">
      <div>
        <span>@copyright by Flindigital</span>
      </div>
      <div className="flex flex-row gap-2 md:gap-3 items-center ml-2 md:ml-auto">
        <span>Version 1.0</span>
        <span>
          <FaCopyright />
        </span>
        <span>2023</span>
      </div>
      <span></span>
    </div>
  );
};
