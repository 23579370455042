import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaXmark } from "react-icons/fa6";

export const ModalTambahComponent = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await onSubmit(formData);
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-row justify-between items-center border-b border-flinneutral-black4 pb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-base md:text-lg leading-6 font-bold">
                    Tambah Data Kategori
                  </Dialog.Title>
                  <div
                    className="flex p-1 rounded-full bg-flinneutral-black4 items-center hover:bg-flinneutral-black5 hover:cursor-pointer "
                    onClick={onClose}>
                    <FaXmark className="text-x" />
                  </div>
                </div>

                <form action="" className="my-2" onSubmit={handleFormSubmit}>
                  <div className="flex flex-col my-1 lg:my-2">
                    <label className="text-xs md:text-sm lg:text-base my-1 lg:my-2 font-light">
                      kategori
                      <span className=" text-flindanger-normal">*</span>
                    </label>
                    <input
                      className="text-xs md:text-sm lg:text-base border border-flinneutral-black5 px-3 py-2 rounded-xl w-full"
                      type="text"
                      placeholder="masukan kategori barang ..."
                      onChange={(e) => setFormData(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mt-5 border-t border-flinneutral-black4 flex flex-row justify-end pt-5 gap-4">
                    <button
                      type="button"
                      onClick={onClose}
                      className="items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                      Batalkan
                    </button>
                    <button
                      type="submit"
                      className="items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
                      { loading ? "Menyimpan..." : "Simpan"}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
