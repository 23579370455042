import React, { useEffect, useState } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { FaEye, FaPencil, FaTrash, FaFileArrowDown } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { API_ENDPOINT_DOCUMENT } from "../../../../api/constants/api";
import { formattedDateToIndonesia } from "../../../../lib/utils/helpers/dateUtils";
import { getOrderStatus } from "../../../../lib/utils/helpers/getOrderStatus";
import { ModalSuccess } from "../../../../components/General/Alert/ModalSuccess";

export const DetailPenggunaManfaat = () => {
  const params = useLocation().state;
  // const paramsBack = useLocation();

  const [dataPenggunaManfaat, setDataPenggunaManfaat] = useState({});

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setDataPenggunaManfaat(params);
  }, [params]);

  const handlePreviewClick = () => {
    const pdfUrl = "your_pdf_file_url.pdf";

    window.open(
      `${API_ENDPOINT_DOCUMENT}/${dataPenggunaManfaat.file_berita_acara}`,
      "_blank"
    );
  };

  const handleDownloadClick = () => {
    const pdfUrl = `${API_ENDPOINT_DOCUMENT}/${dataPenggunaManfaat.file_berita_acara}`;

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";

    link.click();
  };

  // useEffect(() => {
  //   console.log("BACKKKK");
  //   console.log(paramsBack);
  //   if (paramsBack) {
  //     setAlertMessage(paramsBack.message);
  //     setSuccessModalIsOpen(paramsBack.status);
  //   }
  // }, [paramsBack])

  return (
    <>
      <div>
        <HeaderBreadcrumb
          pathName="Data Pengguna Manfaat"
          pathLink="/pengguna-manfaat"
          pageName="Detail Pengguna Manfaat"
        />
        <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
          <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
            <span className="text-sm sm:text-base md:text-lg text-black">
              Detail Pengguna Manfaat
            </span>
            <div className="flex flex-row gap-4">
              <Link to="/pengguna-manfaat/edit" state={params}>
                <div className="flex flex-row text-flinprimary-normal rounded-xl items-center gap-2 border border-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-bg cursor-pointer">
                  <FaPencil />
                  <span className="text-sm">Ubah</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col p-2 sm:p-4 md:p-6 gap-2 md:gap-4">
            {/* DATA NAMA */}
            <div className="flex flex-col md:flex-row border gap-4 md:gap-0 border-flinneutral-black5 bg-flinneutral-black2 p-2 md:p-4 rounded-lg">
              <div className="flex  flex-1 flex-col gap-1">
                <span className="text-xs md:text-sm">Nama</span>
                <h5 className="text-sm md:text-base font-semibold">
                  {dataPenggunaManfaat.staff != null
                    ? dataPenggunaManfaat.staff.nama_lengkap
                    : dataPenggunaManfaat.nama_nonstaff}
                </h5>
              </div>
              <div className="flex flex-1 flex-col gap-1 mr-2">
                <span className="text-xs md:text-sm">NIP</span>
                <h5 className="text-sm md:text-base font-semibold">
                  {dataPenggunaManfaat.staff != null
                    ? dataPenggunaManfaat.staff.nip_nik
                    : dataPenggunaManfaat.nik_nonstaff}
                </h5>
              </div>
              <div className="flex flex-1 flex-col gap-1">
                <span className="text-xs md:text-sm">Jabatan</span>
                <h5 className="text-sm md:text-base font-semibold">
                  {dataPenggunaManfaat.staff != null
                    ? dataPenggunaManfaat.staff.jabatan
                    : "Non Staff"}
                </h5>
              </div>
            </div>
            {/* DATA FILE */}
            {/* <div className="flex flex-col md:flex-row border gap-4 md:gap-0 border-flinneutral-black5 bg-flinneutral-black2 p-2 md:p-4 rounded-lg justify-between items-center">
              <div className="flex flex-1 flex-col gap-1">
                <span className="text-xs md:text-sm">
                  </span>
                <h5 className="text-sm md:text-base font-semibold">
                  
                </h5>
              </div>
              <div className="flex flex-row gap-2 md:gap-4">
                <div className="flex flex-row text-flinprimary-normal rounded-xl items-center gap-2 border border-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-bg cursor-pointer">
                  <FaEye />
                  <span className="text-sm">Preview</span>
                </div>
                <div className="flex flex-row text-white rounded-xl items-center gap-2 border bg-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-normalHover cursor-pointer">
                  <FaFileArrowDown />
                  <span className="text-sm">Download File</span>
                </div>
              </div>
            </div> */}

            <div className="flex flex-col gap-4 md:gap-0  mt-2 md:flex-row-reverse">
              <div className="flex flex-row gap-2 md:gap-4">
                <div
                  className="flex flex-row text-flinprimary-normal rounded-xl items-center gap-2 border border-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-bg cursor-pointer"
                  onClick={handlePreviewClick}>
                  <FaEye />
                  <span className="text-sm">Preview</span>
                </div>
                <div
                  className="flex flex-row text-white rounded-xl items-center gap-2 border bg-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-normalHover cursor-pointer"
                  onClick={handleDownloadClick}>
                  <FaFileArrowDown />
                  <span className="text-sm">Download File</span>
                </div>
              </div>
              <div className="w-full flex-1">
                <span className="text-sm mr-2 sm:text-base md:text-lg text-black">
                  Daftar Barang
                </span>
                <span className="text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                  {(dataPenggunaManfaat.borrowed ?? "").length} Barang
                </span>
              </div>
            </div>

            {/* TABLE */}
            <div className="flex flex-col border rounded-lg bg-white">
              <div className="grid grid-cols-1 overflow-auto rounded-lg">
                <table className="w-full">
                  <thead className=" bg-flinneutral-black3 border-b border-flinneutral-black5">
                    <tr>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Nama Barang
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Kategori Barang
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Tanggal Mulai
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Tanggal Selesai
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(dataPenggunaManfaat.borrowed ?? "").length > 0 ? (
                      (dataPenggunaManfaat.borrowed ?? "").map((item) => (
                        <tr
                          className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4"
                          key={item.id}>
                          <td className="px-3 md:px-6 py-2 text-sm">
                            {(item.itemcode.item ?? "").nama} -{" "}
                            {(item.itemcode ?? "").kode_barang}
                          </td>
                          <td className="px-3 md:px-6 py-2 text-sm">
                            {(item.itemcode.item.kategori ?? "").nama}
                          </td>
                          <td className="px-3 md:px-6 py-2 text-sm">
                            {formattedDateToIndonesia(item.tanggal_mulai)}
                          </td>
                          <td className="px-3 md:px-6 py-2 text-sm">
                            {formattedDateToIndonesia(item.tanggal_akhir)}
                          </td>
                          <td className="px-3 md:px-6 py-3 text-sm">
                            {getOrderStatus(item.status_pinjam)}
                            {/* <span
                              className={`bg-${
                                item.status_pinjam == 0 ? "green" : "blue"
                              }-100 text-${
                                item.status_pinjam == 0 ? "green" : "blue"
                              }-600 font-semibold text-xs md:text-sm p-1.5 px-2 md:px-4 rounded-full`}>
                              {item.status_pinjam == 0 ? "Aktif" : "Selesai"}
                            </span> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center h-12">
                        <td colSpan={12}>Tidak ada data barang</td>
                      </tr>
                    )}

                    {/* <tr className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                      <td className="px-3 md:px-6 py-2 text-sm">
                        Honda Beat 2015 - BEAT001
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        Barang Bergerak
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        02 Oktober 2023
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        02 Desember 2023
                      </td>
                      <td className="px-3 md:px-6 py-3 text-sm">
                        <span className="bg-green-100 text-green-600 font-semibold text-xs md:text-sm p-1.5 px-2 md:px-4 rounded-full">
                          Selesai
                        </span>
                      </td>
                    </tr>
                    <tr className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                      <td className="px-3 md:px-6 py-2 text-sm">
                        Honda Beat 2015 - BEAT001
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        Barang Bergerak
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        02 Oktober 2023
                      </td>
                      <td className="px-3 md:px-6 py-2 text-sm">
                        02 Desember 2023
                      </td>
                      <td className="px-3 md:px-6 py-3 text-sm">
                        <span className="bg-blue-100 text-blue-600 font-semibold text-xs md:text-sm p-1.5 px-2 md:px-4 rounded-full">
                          Aktif
                        </span>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalSuccess isOpen={successModalIsOpen} message={alertMessage} />
    </>
  );
};
