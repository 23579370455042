import React, { useEffect } from "react";
import { FaCalendarDays, FaAlignJustify } from "react-icons/fa6";
import { getFormatterDate } from "../../lib/utils/helpers/dateUtils";
import { ProfileLink } from "../../components/Dashboard/ProfileLink";

export const Navbar = ({ onToggleSidebar }) => {
  const handleLogout = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
      return;
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  const formattedDate = getFormatterDate();
  return (
    <div className="bg-white h-20 px-5 justify-between flex flex-row items-center border-b border-flinneutral-black4 transition-transform ease-in-out duration-300">
      {/* LEFT SIDE */}
      <div className="flex flex-row items-center">
        <button className="md:hidden" onClick={onToggleSidebar}>
          <FaAlignJustify className="text-base md:text-xl" />
        </button>
        <div className=" p-2 -5 mr-0 md:mr-5">
          <span className="border-0 md:border-r-2 border-flinneutral-black5 pr-3 md:pr-6 text-base md:text-2xl font-semibold md:font-extrabold text-flinprimary-normal">
            SIAP BISA
          </span>
        </div>
        <div className="h-12 px-4 items-center hidden md:flex gap-2 rounded-lg border border-flinneutral-black5  text-flinneutral-black8">
          <FaCalendarDays className="text-lg" />
          <span className="text-sm">{formattedDate}</span>
        </div>
      </div>
      {/* RIGHT SIDE */}
      <ProfileLink />
    </div>
  );
};
