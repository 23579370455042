import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { FaCircleCheck, FaXmark } from "react-icons/fa6";
export const ModalSuccess = ({ isOpen, onClose, message }) => {
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibility(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [onClose]);

  useEffect(() => {
    setVisibility(isOpen);
  }, [isOpen]);

  return (
    <Transition show={visibility ?? false} as={Fragment}>
      <div className="fixed top-4 right-4 bg-[#7BA304] text-white rounded-md p-4 shadow-md transition-all duration-300">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <FaCircleCheck className="text-2xl" />
            <span>{message}</span>
          </div>
          <button
            onClick={() => {
              setVisibility(false);
            }}>
            <FaXmark className="ml-4 text-sm" />
          </button>
        </div>
      </div>
    </Transition>
  );
};
