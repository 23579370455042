export const columns = [
  {
    header: "Nama Barang",
    accessorKey: "nama",
  },
  {
    header: "Kategori Barang",
    accessorKey: "category_name",
  },
  {
    header: "Jumlah Barang",
    accessorKey: "total",
  },
];
