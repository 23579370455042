import React from "react";
import { Link, useLocation } from "react-router-dom";
import classname from "classname";

const LinkClasess = " flex flex-row items-center px-6 py-4 gap-2";

function SidebarLink({ item, onClick }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={item.path}
      onClick={onClick}
      className={classname(
        pathname.startsWith(item.path)
          ? "bg-flinprimary-light border-r-4 border-flinprimary-normal text-flinprimary-normal"
          : "bg-white text-flinneutral-black7",
        LinkClasess
      )}>
      <span className="text-xl">{item.icon}</span>
      <span className=" text-sm capitalize">{item.label}</span>
    </Link>
  );
}

export default SidebarLink;
