import React from "react";
import { FaChevronRight, FaSquarePlus } from "react-icons/fa6";
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";

export const HeaderSubDashboardModal = ({ title, totalData, onAddClick }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row gap-4 items-center">
        <span className="text-sm sm:text-base md:text-xl font-bold text-black">
          {title}
        </span>
        {totalData && (
          <span className="text-xs md:text-sm p-2 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
            {totalData}
          </span>
        )}
      </div>
      {onAddClick && (
        <button
          type="button"
          onClick={onAddClick}
          className="flex flex-row gap-3 items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover cursor-pointer rounded-lg md:rounded-xl text-white px-2 md:px-4 py-2 ">
          <FaSquarePlus className="text-base md:text-xl" /> Tambah {title}
        </button>
      )}
    </div>
  );
};
export const HeaderSubDashboardLink = ({ title, totalData, onLinkView }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-4 md:gap-0">
      <div className="flex flex-row gap-2 md:gap-4 items-center">
        <span className="text-sm sm:text-base md:text-xl font-bold text-black">
          {title}
        </span>
        <span className="text-xs md:text-sm p-2 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
          {totalData}
        </span>
      </div>
      <Link to={onLinkView}>
        <button
          type="button"
          className="flex flex-row gap-3 items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover cursor-pointer rounded-lg md:rounded-xl text-white px-2 md:px-4 py-2">
          <FaSquarePlus className="text-base md:text-xl" /> Tambah {title}
        </button>
      </Link>
    </div>
  );
};
export const HeaderBreadcrumb = ({ pathName, pathLink, pageName }) => {
  return (
    <div className="flex flex-row gap-1 md:gap-3 items-center mt-3">
      <Link to={pathLink}>
        <div className="flex flex-row items-center gap-2">
          <div className="px-1 border-2 border-black rounded-lg items-center text-lg flex">
            <TbArrowBackUp />
          </div>
          <span className="text-sm sm:text-base md:text-xl font-bold text-black">
            {pathName}
          </span>
          <FaChevronRight className="text-xl" />
        </div>
      </Link>
      <span className="text-flinneutral-black7 text-sm md:text-base font-semibold">
        {pageName}
      </span>
    </div>
  );
};
