import React, { useEffect, useState } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { FormLabel } from "../../../../components/Dashboard/Form";
import { MyRadioGroup } from "../../../../components/General/Form/MyRadioGroup";
import {
  FaMagnifyingGlass,
  FaSquarePlus,
  FaFileArrowUp,
  FaFilePdf,
  FaXmark,
  FaTrashCan,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../../../api/Api";
import { ModalSuccess } from "../../../../components/General/Alert/ModalSuccess";
import { ModalError } from "../../../../components/General/Alert/ModalError";

export const TambahPenggunaManfaat = () => {
  const navigate = useNavigate();

  const [jenisStaf, setJenisStaf] = useState("staff");
  const [selectedStaff, setSelectedStaff] = useState({});
  // NONSTAFF
  const [namaNonStaf, setNamaNonStaf] = useState("");
  const [nikNonStaf, setNikNonStaf] = useState("");

  const [listStaff, setListStaff] = useState([]);
  const [listBarang, setListBarang] = useState([]);
  const [beritaAcara, setBeritaAcara] = useState(null);
  const [fileNameBeritaAcara, setFileNameBeritaAcara] = useState("");
  const [sizeFile, setSizeFile] = useState();
  const [loading, setLoading] = useState(false);

  const radioOptions = [
    { value: "staff", label: "Staff" },
    { value: "nonstaf", label: "Non Staff" },
  ];
  const [barang, setBarang] = useState([
    {
      id: 1,
      namaBarang: "",
      kodeBarang: "",
      tanggalMulai: "",
      tanggalAkhir: "",
    },
  ]);
  const [maxId, setmaxId] = useState(1);
  const addBarang = () => {
    const newId = maxId + 1;
    setmaxId(newId);
    setBarang([
      ...barang,
      {
        id: newId,
        namaBarang: "",
        kodeBarang: "",
        tanggalMulai: "",
        tanggalAkhir: "",
      },
    ]);
  };
  const handleBarangChange = (index, fieldName, value) => {
    const updatedBarang = [...barang];
    updatedBarang[index][fieldName] = value;
    setBarang(updatedBarang);
  };
  const removeBarang = (index) => {
    setBarang((prevBarang) => {
      const updatedBarang = [...prevBarang];
      updatedBarang.splice(index, 1);
      return updatedBarang;
    });
  };

  const fetchDataStaff = async (page = 1) => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/staff/all`);
      setListStaff(result.data);
    } catch (error) {
      setAlertMessage("Data list staff gagal di ambil");
      setErrorModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataBarang = async () => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/barang/tersedia`);
      setListBarang(result.data);
    } catch (error) {
      setAlertMessage("Data list barang gagal di ambil");
      setErrorModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataStaff();
    fetchDataBarang();
  }, []);

  const resetForm = () => {
    setJenisStaf("staf");
    selectedStaff({});
    setNamaNonStaf("");
    setNikNonStaf("");
    setBarang([
      {
        id: 1,
        namaBarangKodeBarang: "",
        tanggalMulai: "",
        tanggalAkhir: "",
      },
    ]);
    setmaxId(1);
    setBeritaAcara(null);
    setFileNameBeritaAcara("");
    setSizeFile("");
  };

  const addPenggunaManfaat = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData();
      const jenisPenggunaanTemp = jenisStaf == "staff" ? "Staff" : "NonStaff";

      formData.append("jenis_penggunaan", jenisPenggunaanTemp);
      formData.append("nip_atau_nik_penggunaan", selectedStaff.nip_nik);
      formData.append("nama_nostaff_penggunaan", namaNonStaf);
      formData.append("nik_nostaff_penggunaan", nikNonStaf);
      barang.forEach((item, index) => {
        formData.append(`kode_barang_penggunaan[${index}]`, item.kodeBarang);
        formData.append(
          `tanggal_pakai_penggunaan[${index}]`,
          item.tanggalMulai
        );
        formData.append(
          `tanggal_selesai_penggunaan[${index}]`,
          item.tanggalAkhir
        );
      });
      formData.append("berita_acara_penggunaan", beritaAcara);

      await Api.postData(`/penggunaan-barang`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/pengguna-manfaat", {
        state: {
          successMassage: "Pengguna manfaat berhasil ditambahkan",
        },
      });
    } catch (error) {
      navigate("/pengguna-manfaat", {
        state: {
          errorMessage: "Gagal menambahkan pengguna manfaat",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  return (
    <>
      <div>
        <HeaderBreadcrumb
          pathName="Data Pengguna Manfaat"
          pathLink="/pengguna-manfaat"
          pageName="Tambah Pengguna Manfaat"
        />
        <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
          {/* HEADER */}
          <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
            <span className="text-sm sm:text-base md:text-lg text-black">
              Tambah Pengguna Manfaat
            </span>
          </div>

          {/* FORM CONTENT */}
          <form action="" onSubmit={addPenggunaManfaat}>
            <div className="flex flex-col px-2 sm:px-4 md:px-6 pb-4">
              <div className="flex flex-col md:flex-row md:gap-4">
                {/* NAMA BARANG */}
                <div className="flex flex-col flex-1">
                  <FormLabel labelName="Jenis" required={true} />
                  <MyRadioGroup
                    value={jenisStaf}
                    onChange={(e) => {
                      if ("staff" === e) {
                        setSelectedStaff({});
                      } else {
                        setNamaNonStaf("");
                        setNikNonStaf("");
                      }

                      setJenisStaf(e);
                    }}
                    options={radioOptions}
                  />
                </div>
              </div>

              {jenisStaf == "staff" ? (
                <>
                  {/* NAMA STAFF */}
                  <div className="flex flex-col flex-1">
                    <FormLabel labelName="Nama Staff" required={true} />
                    <div className="flex-1 relative">
                      <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                      <select
                        className="text-xs sm:text-sm border-2 border-flinneutral-black4 pl-8 px-3 py-2.5 rounded-xl w-full"
                        type="text"
                        name="kategori-barang"
                        // value={selectedStaff.nama_lengkap}
                        onChange={(e) => {
                          const temp = e.target.selectedIndex - 1;
                          if (temp >= 0) {
                            setSelectedStaff(listStaff[temp]);
                          }
                        }}
                        placeholder="cari masukan nama staf"
                        required>
                        <option
                          value=""
                          disabled={Object.keys(selectedStaff).length !== 0}
                          defaultChecked
                          className=" text-flinneutral-black7">
                          Pilih Staf
                        </option>
                        {listStaff.map((dataStaff) => (
                          <option
                            key={dataStaff.kode}
                            value={dataStaff.nama_lengkap}>
                            {dataStaff.nama_lengkap} - {dataStaff.nip_nik}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* NON STAFF */}
                  <div className="flex flex-col flex-1">
                    <FormLabel labelName="Nama Non-Staff" required={true} />
                    <input
                      className="text-xs md:text-sm lg:text-base border border-flinneutral-black5 px-3 py-2 rounded-xl w-full"
                      type="text"
                      value={namaNonStaf}
                      onChange={(e) => {
                        setNamaNonStaf(e.target.value);
                      }}
                      placeholder="masukan nama non-staf ..."
                      required
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <FormLabel labelName="Nik Non-Staff" required={true} />
                    <input
                      className="text-xs md:text-sm lg:text-base border border-flinneutral-black5 px-3 py-2 rounded-xl w-full"
                      type="text"
                      value={nikNonStaf}
                      onChange={(e) => {
                        setNikNonStaf(e.target.value);
                      }}
                      placeholder="masukan nik staf ..."
                      required
                    />
                  </div>
                </>
              )}

              {/* BARANG */}
              <div className="flex flex-row items-center gap-2">
                <FormLabel labelName="Barang" required={true} />
                <span className="my-2 md:mt-4 text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                  {barang.length} Barang
                </span>
              </div>

              <div className="text-xs sm:text-sm flex flex-col gap-2 md:gap-4 my-2">
                {barang.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col rounded-lg border pb-2 md:pb-4 mb-4">
                    <div className="flex flex-row border-b justify-between items-center p-4">
                      <span className="font-semibold">Data Barang </span>
                      <div
                        onClick={() => removeBarang(index)}
                        className="flex flex-row text-flindanger-normal rounded-xl items-center gap-2 border border-flindanger-normal py-1.5 px-3 hover:bg-flindanger-bg cursor-pointer">
                        <FaTrashCan />
                        <span className="text-sm">Hapus</span>
                      </div>
                    </div>
                    <div className="flex flex-col px-2 md:px-4">
                      <FormLabel
                        labelName="Nama Barang - Kode Barang"
                        required={true}
                      />
                      <div className="flex-1 relative">
                        <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 left-3" />
                        <select
                          className="text-xs sm:text-sm  border-2 border-flinneutral-black4 pl-8 px-3 py-2.5 rounded-xl w-full"
                          type="text"
                          name="namaBarangKodeBarang"
                          onChange={(e) => {
                            const tempIndex = e.target.selectedIndex - 1;

                            handleBarangChange(
                              index,
                              "namaBarang",
                              listBarang[tempIndex].nama
                            );
                            handleBarangChange(
                              index,
                              "kodeBarang",
                              listBarang[tempIndex].kode_barang
                            );
                          }}
                          required>
                          <option
                            value=""
                            disabled={item.namaBarang !== ""}
                            defaultChecked>
                            Pilih Barang
                          </option>
                          {listBarang.map((dataBarang) => (
                            <option
                              key={dataBarang.kode_barang}
                              value={dataBarang.nama}>
                              {dataBarang.nama} - {dataBarang.kode_barang}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="flex-1 flex flex-col px-2 md:px-4">
                        <FormLabel labelName="Tanggal Mulai" required={true} />
                        <input
                          className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                          type="date"
                          value={item.tanggalMulai}
                          onChange={(e) =>
                            handleBarangChange(
                              index,
                              "tanggalMulai",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="flex-1 flex flex-col px-2 md:px-4">
                        <FormLabel labelName="Tanggal Akhir" required={true} />
                        <input
                          className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                          type="date"
                          value={item.tanggalAkhir}
                          onChange={(e) =>
                            handleBarangChange(
                              index,
                              "tanggalAkhir",
                              e.target.value
                            )
                          }
                          min={item.tanggalMulai}
                          required
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* BUTTON ADD */}
              <div
                onClick={addBarang}
                className="flex flex-row items-center border-2 border-dashed border-flinprimary-normal p-2 rounded-xl gap-2 justify-center hover:bg-flinprimary-bg cursor-pointer text-flinprimary-normal">
                <FaSquarePlus className="text-xs md:text-sm" />
                <span className="text-xs md:text-sm font-normal">
                  Tambah Barang
                </span>
              </div>
              {/* ADD FILE */}
              <div className="flex flex-col flex-1">
                <FormLabel
                  labelName="Upload File Berita Acara"
                  required={true}
                />
                <div
                  onClick={() => document.querySelector("#pdfFile").click()}
                  className="rounded-lg flex flex-col items-center border-2 border-dashed border-flinneutral-black5 p-4 md:p-6 cursor-pointer gap-2 md:gap-4">
                  <FaFileArrowUp className="text-2xl md:text-4xl text-flinprimary-normal" />
                  <span className="text-xs md:text-sm">Unggah File</span>

                  <div
                    className={`w-full flex flex-row items-center gap-2 md:gap-4 p-2 md:p-4 border rounded-lg ${
                      fileNameBeritaAcara !== "" ? "" : "hidden"
                    }`}>
                    <FaFilePdf className="text-lg text-flinneutral-black7" />
                    <div className="flex flex-col flex-1 gap-0.5">
                      <span className="text-xs">{fileNameBeritaAcara}</span>
                      <span className="text-xs text-flinneutral-black7">
                        {sizeFile}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setBeritaAcara(null);
                        setFileNameBeritaAcara("");
                        setSizeFile("");
                      }}
                      className="flex p-1 rounded-full bg-flinneutral-black4 items-center hover:bg-flinneutral-black5 hover:cursor-pointer">
                      <FaXmark className="text-x" />
                    </div>
                  </div>
                  <input
                    type="file"
                    accept=".pdf"
                    id="pdfFile"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files && files[0]) {
                        setFileNameBeritaAcara(files[0].name);
                        setSizeFile(
                          `(${(files[0].size / (1024 * 1024)).toFixed(2)} MB)`
                        );
                        setBeritaAcara(files[0]);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row text-xs text-flinneutral-black7 my-2 justify-between">
                <span>Format file yang didukung : PDF</span>
                <span>Maksimal 5 MB</span>
              </div>
              {/* FOOTER FORM */}
              <div className="mt-5 border-t border-flinneutral-black4 flex flex-row pt-5 justify-between items-center">
                <span
                  onClick={resetForm}
                  className="text-sm md:text-base text-flinneutral-black7 cursor-pointer hover:text-flinneutral-black6">
                  Reset
                </span>
                <div className="flex flex-row items-center gap-4">
                  <Link to="/pengguna-manfaat">
                    <button
                      type="button"
                      className="items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                      Batalkan
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ModalSuccess
        isOpen={successModalIsOpen}
        onClose={closeSuccessModal}
        message={alertMessage}
      />
      <ModalError
        isOpen={errorModalIsOpen}
        onClose={closeErrorModal}
        message={alertMessage}
      />
    </>
  );
};
