import React, { useState, useEffect } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { FormLabel } from "../../../../components/Dashboard/Form";
import { FaSquarePlus, FaTrashCan } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../../../api/Api";

export const TambahBarang = () => {
  const navigate = useNavigate();

  const [dataKategori, setDataKategori] = useState([]);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState(null);
  const [selectedKategori, setSelectedKategori] = useState("");

  const [name, setName] = useState("");
  const [kodeBarang, setKodeBarang] = useState([{ id: 1, kode: "" }]);
  const [maxId, setMaxId] = useState(1);
  const addKodeBarang = () => {
    const newId = maxId + 1;
    setMaxId(newId);
    setKodeBarang((prev) => [...prev, { id: newId, kode: "" }]);
  };

  const removeKodeBarang = (id) => {
    setKodeBarang((section) => section.filter((section) => section.id !== id));
  };

  const addBarang = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const rawData = {
        nama_barang: name,
        kategori_barang: selectedKategori,
        kode_unik_barang: kodeBarang.map((obj) => obj.kode),
      };

      await Api.postData("/barang", rawData);
      // Redirect to the index page and send parameter
      navigate("/data-barang", {
        state: {
          successMessage: "Barang berhasil ditambahkan",
        },
      });
    } catch (error) {
      setError(error);
      navigate("/data-barang", {
        state: {
          errorMessage: "Gagal menambahkan barang",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const updateKodeBarang = (e) => {
    try {
      const selectedId = e.target.name;
      const selectedObjectIndex = kodeBarang.findIndex(
        (item) => `${item.id}` === `${selectedId}`
      );

      if (selectedObjectIndex !== -1) {
        const updatedKodeBarang = [...kodeBarang];
        updatedKodeBarang[selectedObjectIndex] = {
          ...updatedKodeBarang[selectedObjectIndex],
          kode: e.target.value,
        };

        setKodeBarang(updatedKodeBarang);
      }
    } catch (error) {
      console.error("Error updating kode barang:", error);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const fetchCategoryData = async () => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/kategori/all`);
      setDataKategori(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <HeaderBreadcrumb
        pathName="Data Barang"
        pathLink="/data-barang"
        pageName="Tambah Barang"
      />
      <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
        {/* HEADER */}
        <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
          <span className="text-sm sm:text-base md:text-lg text-black">
            Tambah Barang
          </span>
        </div>

        {/* FORM CONTENT */}
        <form action="" onSubmit={addBarang}>
          <div className="flex flex-col px-2 sm:px-4 md:px-6 pb-4">
            <div className="flex flex-col md:flex-row md:gap-4">
              {/* NAMA BARANG */}
              <div className="flex flex-col flex-1">
                <FormLabel labelName="Nama Barang" required={true} />
                <input
                  className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                  type="text"
                  name="nama-barang"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="masukan nama barang ..."
                  required
                />
              </div>
              <div className="flex flex-col flex-1">
                {/* KATEGORI BARANG */}
                <FormLabel labelName="Kategori Barang" required={true} />
                <select
                  className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2.5 rounded-xl w-full"
                  type="text"
                  name="kategori-barang"
                  onChange={(e) => {
                    setSelectedKategori(e.target.value);
                  }}
                  placeholder="masukan kategori barang ..."
                  required>
                  <option
                    key=""
                    value=""
                    disabled={selectedKategori !== ""}
                    defaultChecked
                    className=" text-flinneutral-black7">
                    Pilih Kategori
                  </option>
                  {dataKategori.map((kategori) => (
                    <option key={kategori.kode} value={kategori.kode}>
                      {kategori.nama}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* KODE BARANG */}
            <div className="flex flex-row items-center gap-2">
              <FormLabel labelName="Kode Barang" required={true} />
              <span className="my-2 md:mt-4 text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                {kodeBarang.length} Barang
              </span>
            </div>
            <div className="flex flex-col gap-2 md:gap-4">
              {kodeBarang.map((section) => (
                <div
                  key={section.id}
                  className="flex flex-row gap-2 md:gap-4 items-center">
                  <input
                    className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
                    type="text"
                    onChange={(e) => {
                      updateKodeBarang(e);
                    }}
                    name={`${section.id}`}
                    placeholder="masukan kode barang ..."
                    required
                  />
                  <div
                    onClick={() => removeKodeBarang(section.id)}
                    className="flex flex-row text-flindanger-normal rounded-xl items-center gap-2 border border-flindanger-normal py-1.5 px-3 hover:bg-flindanger-bg cursor-pointer">
                    <FaTrashCan />
                    <span className="text-sm">Hapus</span>
                  </div>
                </div>
              ))}
              <div
                onClick={() => {
                  addKodeBarang();
                }}
                className="flex flex-row items-center border-2 border-dashed border-flinprimary-normal p-2 rounded-xl gap-2 justify-center hover:bg-flinprimary-bg cursor-pointer text-flinprimary-normal">
                <FaSquarePlus className="text-xs md:text-sm" />
                <span className="text-xs md:text-sm font-normal">
                  Tambah Kode Barang
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 border-t border-flinneutral-black4 flex flex-row justify-end pt-5 gap-4 px-2 sm:px-4 md:px-6">
            <Link to="/data-barang">
              <button
                type="button"
                className="items-center text-xs md:text-base bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinprimary-normal px-5 py-2 shadow-md">
                Batalkan
              </button>
            </Link>
            <button
              type="submit"
              className="items-center text-xs md:text-base bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2 shadow-md">
              {loading ? "Meyimpan" : "Simpan"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
