import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import defaultFoto from "../../../asset/image/dashboard/fotorofil.jpeg";
import Api from "../../../api/Api";
import { API_ENDPOINT_DOCUMENT } from "../../../api/constants/api";

export const EditProfil = () => {
  const navigate = useNavigate();
  const params = useLocation().state;

  const [selectedFotoProfile, setSelectedFotoProfile] = useState(null);
  const [defaultFotoProfile, setDefaultFotoProfile] = useState(null);
  const [namaStaf, setNamaStaf] = useState("");
  const [nip, setNip] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [email, setEmail] = useState("");
  const [noHp, setNoHp] = useState("");
  const [alamat, setAlamat] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (params) {
      setDefaultFotoProfile(params.foto_profile);
      setNamaStaf(params.nama);
      setNip(params.nip_nik);
      setJabatan(params.jabatan);
      setEmail(params.email);
      setNoHp(params.no_hp);
      setAlamat(params.alamat);
    }
  }, [params])

  const updateProfile = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const formData = {
        'foto_profile': selectedFotoProfile,
        'nama_lengkap': namaStaf,
        'nip_atau_nik': nip,
        'jabatan': jabatan,
        'email': email,
        'nomor_hp': noHp,
        'alamat': alamat,
      }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const result = await Api.postData('/staff-admin/profile?_method=PUT',
        formData, config
      );
      localStorage.setItem("foto_profile", result.data.foto_profile);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        console.log('Validation errors:', error.response.data.errors);
      } else {
        console.error('API call error:', error.message);
      }
      setError(error.message);
    } finally {
      setLoading(false);
      navigate(-1);
    }
  }

  const handleFotoProfileChange = async (e) => {
    setSelectedFotoProfile(e);
  }

  const loadDefaultFotoProfile = () => {
    if(defaultFotoProfile) {
      return `${API_ENDPOINT_DOCUMENT}/${defaultFotoProfile}`;
    } else {
      return defaultFoto;
    }
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-4 items-center">
          <span className="text-sm sm:text-base md:text-xl font-bold text-black">
            Profil
          </span>
        </div>
      </div>
      <div className="flex flex-col mt-4 pb-4 rounded-lg bg-white">
        <form action="" method="post" onSubmit={updateProfile}>
          <div className="flex flex-row p-4 justify-between border-b-2 border-flinneutral-black4">
            <span className="text-sm sm:text-base md:text-xl font-bold text-black">
              Edit Akun User
            </span>
            <div className="flex flex-row gap-4">
              <Link to="/profil">
                <button className="items-center text-xs md:text-sm bg-white hover:bg-flinneutral-black3 hover:cursor-pointer rounded-lg md:rounded-xl text-flinneutral-black7 px-5 py-2 border-2 border-flinneutral-black4">
                  Batal
                </button>
              </Link>
              <button
                type="submit"
                className="items-center text-xs md:text-sm bg-flinprimary-normal hover:bg-flinprimary-normalHover hover:cursor-pointer rounded-lg md:rounded-xl text-white px-5 py-2">
                Simpan
              </button>
            </div>
          </div>
          <div className="flex flex-col p-4">
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Foto Profil
            </label>
            <div className="flex flex-row items-center gap-5">
              <img
                src={(selectedFotoProfile) ? URL.createObjectURL(selectedFotoProfile) : loadDefaultFotoProfile()}
                className=" object-cover w-[80px] h-[80px] rounded-3xl border-2 border-flinneutral-black4 bg-flinprimary-normal"
              />
              <div
                onClick={() => document.querySelector("#fotoProfil").click()}
                className="flex flex-row gap-2 md:gap-4 text-xs sm:text-sm md:text-base text-flinneutral-black7 border-2 border-flinneutral-black4 py-1.5 px-4 rounded-lg items-center hover:bg-flinneutral-black2 cursor-pointer">
                <FaPencil className="text-base" />
                <span>Ganti Foto</span>
                <input
                  type="file"
                  accept="image/*"
                  id="fotoProfil"
                  hidden
                  onChange={({ target: { files } }) => {
                    if (files && files.length > 0) {
                      handleFotoProfileChange(files[0]);
                    }
                  }}
                />
              </div>
            </div>

            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Nama Staf
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="text"
              value={namaStaf}
              onChange={(e) => setNamaStaf(e.target.value)}
              placeholder="masukan nama staf ..."
              required
            />
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              NIP/NIK
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="number"
              value={nip}
              onChange={(e) => setNip(e.target.value)}
              placeholder="masukan NIP/NIK ..."
              required
            />
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Jabatan
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="text"
              value={jabatan}
              onChange={(e) => setJabatan(e.target.value)}
              placeholder="masukan jabatan ..."
              required
            />
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Email
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="masukan email ..."
              required
            />
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Nomor HP
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="number"
              value={noHp}
              onChange={(e) => setNoHp(e.target.value)}
              placeholder="masukan nomor hp ..."
              required
            />
            <label className="text-xs sm:text-sm md:text-base  my-2 md:mt-4 font-light">
              Alamat
              <span className=" text-flindanger-normal">*</span>
            </label>
            <input
              className="text-xs sm:text-sm md:text-base  border-2 border-flinneutral-black4 px-3 py-2 rounded-xl w-full"
              type="text"
              value={alamat}
              onChange={(e) => setAlamat(e.target.value)}
              placeholder="masukan alamat ..."
              required
            />
          </div>
        </form>
      </div>
    </>
  );
};
