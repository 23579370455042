import axios from 'axios';
import { API_ENDPOINT } from '../constants/api';

const apiCaller = axios.create({
  baseURL: API_ENDPOINT,
});

apiCaller.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiCaller;