import React, { useState, useEffect } from "react";
import { HeaderSubDashboardLink } from "../../../components/Dashboard/HeaderSubDashboard";
import {
  FaChevronLeft,
  FaChevronRight,
  FaEye,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import { BsUiChecksGrid } from "react-icons/bs";
import { columns } from "./columns";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ModalFilter } from "../../../components/Dashboard/pengguna-manfaat/ModalFilter";
import Api from "../../../api/Api";
import { ModalSuccess } from "../../../components/General/Alert/ModalSuccess";
import { ModalError } from "../../../components/General/Alert/ModalError";

export const PenggunaManfaat = () => {
  // const paramsBack = useLocation().state;
  const location = useLocation();
  const navigate = useNavigate();

  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [dataPenggunaManfaat, setDataPenggunaManfaat] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // ALERT
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  //VARIABLE FOR MODAL
  let [modalFilterIsOpen, modalFilterSetIsOpen] = useState(false);
  function closeModalFilter() {
    modalFilterSetIsOpen(false);
  }
  function openModalFilter() {
    modalFilterSetIsOpen(true);
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/penggunaan-barang`);
      setDataPenggunaManfaat(result);
      const newData = result.data.map((item) => {
        const name = item.staff ? item.staff.nama_lengkap : item.nama_nonstaff;
        const nipNik = item.staff ? item.staff.nip_nik : item.nik_nonstaff;
        const namaBarang = item.borrowed.map((borrowedItem) => {
          const itemCode = borrowedItem.itemcode.kode_barang;
          const itemName = borrowedItem.itemcode.item.nama;
          return `${itemName} - ${itemCode}`;
        });

        const statusBarang =
          item.borrowed.every((v) => v.status_pinjam === 1) === true ||
          namaBarang.length === 0
            ? 1
            : 0;
        return {
          id: item.id,
          kode: item.kode,
          name: name,
          nipNik: nipNik,
          namaBarang: namaBarang.join(", "),
          statusBarang: statusBarang,
        };
      });

      setData(newData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    if (location.state && location.state.successMessage) {
      // Display success notification
      setAlertMessage(location.state.successMessage);
      setSuccessModalIsOpen(true);
    } else if (location.state && location.state.errorMessage) {
      // Display error notification
      setAlertMessage(location.state.errorMessage);
      setErrorModalIsOpen(true);
    }

    // Clear the state parameters
    navigate(location.pathname, { replace: true });
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
  });
  return (
    <>
      <HeaderSubDashboardLink
        title="Pengguna Manfaat"
        totalData={`${data.length}`}
        onLinkView="tambah"
      />
      {/* TABLE */}
      <div className="mt-4 flex flex-col border border-flinneutral-black5 rounded-lg bg-white">
        {/* TABLE HEADER */}
        <div className="grid grid-cols-1">
          <div className="flex flex-row justify-between items-end md:items-center px-3 md:px-6 py-4">
            {/* PAGE SIZE */}
            <div className="flex items-center flex-col md:flex-row gap-3">
              <span className="text-base">Menampilkan</span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="rounded-lg p-2 border w-24 text-sm text-flinneutral-black7">
                {[5, 10, 20, 30, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    {pageSize} Data
                  </option>
                ))}
              </select>
            </div>
            {/* SEARCH and FILTER*/}
            <div className="flex  flex-row items-center gap-2 md:gap-4 sm:3/5 md:w-3/6">
              <div className="flex-1 relative">
                <FaMagnifyingGlass className="w-4 h-4 text-flinneutral-black7 z-10 absolute top-1/2 transform -translate-y-1/2 right-3" />
                <input
                  type="text"
                  value={filtering}
                  onChange={(e) => setFiltering(e.target.value)}
                  className="border border-flinneutral-black5 rounded-lg pr-10 px-3 py-2 text-sm w-full text-flinneutral-black7"
                  placeholder="Cari Pengguna Manfaat ..."
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  openModalFilter();
                }}
                className="flex flex-row gap-3 items-center rounded-lg p-2 border border-flinneutral-black5 text-sm text-flinneutral-black7">
                <BsUiChecksGrid className="text-sm md:text-base" /> Filter Data
              </button>
            </div>
          </div>
        </div>
        {/* END TABLE HEADER */}
        <div className="grid grid-cols-1 overflow-auto ">
          <table className="w-full">
            <thead className=" bg-flinneutral-black3 border-t border-b border-flinneutral-black5">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "⬆️", desc: "⬇️" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </th>
                  ))}
                  <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left w-28 ">
                    Aksi
                  </th>
                </tr>
              ))}
            </thead>

            <tbody>
              {loading ? (
                <tr className="text-center h-12">
                  <td colSpan={columns.length + 1}>Loading...</td>
                </tr>
              ) : table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-3 md:px-6 py-3 text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                    <td className="flex flex-row gap-4 px-3 md:px-6 py-3 text-sm text-flinneutral-black7">
                      <Link
                        to="detail"
                        state={dataPenggunaManfaat.data[row.index]}>
                        <span className="flex flex-row items-center gap-2 shadow-md bg-white text-flinprimary-normal font-semibold text-sm md:text-base p-1 px-2 md:px-4 rounded-lg">
                          <FaEye /> Detail
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center h-12">
                  <td colSpan={12}>Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-1 px-3 md:px-6 py-4">
          <div className="flex flex-col gap-2 md:flex-row items-center justify-center md:justify-between">
            {/* LEFT TABLE FOOTER */}
            <div className="flex flex-row gap-4">
              Halaman
              <strong>{table.getState().pagination.pageIndex + 1}</strong>
              <span>dari</span>
              <strong>{table.getPageCount()}</strong>
            </div>
            {/* RIGHT TABLE FOOTER */}
            <div className="flex items-center">
              <button
                className={`mx-2 my-2 cursor-pointer ${
                  table.getCanPreviousPage()
                    ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                    : "text-flinneutral-black7"
                } `}
                disabled={!table.getCanPreviousPage}
                onClick={() => table.setPageIndex(0)}>
                Halaman Pertama
              </button>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg  ${
                  !table.getCanPreviousPage()
                    ? " bg-flinneutral-black4"
                    : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                }`}
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.previousPage()}>
                <FaChevronLeft />
              </button>
              <button
                className={`border p-2 mx-2 my-2 cursor-pointer rounded-lg ${
                  !table.getCanNextPage()
                    ? "bg-flinneutral-black4"
                    : "text-white bg-flinprimary-normal hover:bg-flinprimary-normalHover"
                }`}
                disabled={!table.getCanNextPage()}
                onClick={() => table.nextPage()}>
                <FaChevronRight />
              </button>
              <button
                className={`mx-2 my-2 cursor-pointer ${
                  table.getCanNextPage()
                    ? "  text-flinprimary-normal hover:text-flinprimary-normalHover"
                    : "text-flinneutral-black7"
                } `}
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                Halaman terakhir
              </button>
            </div>
          </div>
        </div>
        {/* TABLE CONTENT */}

        {/* END TABLE CONTENT */}
      </div>
      {/* END TABLE */}
      <ModalSuccess
        isOpen={successModalIsOpen}
        onClose={() => setSuccessModalIsOpen(false)}
        message={alertMessage}
      />
      <ModalError
        isOpen={errorModalIsOpen}
        message={alertMessage}
        onClose={() => setErrorModalIsOpen(false)}
      />
    </>
  );
};
