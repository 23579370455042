export const columns = [
  {
    header: "Nama",
    accessorKey: "nama_lengkap",
  },
  {
    header: "Nip",
    accessorKey: "nip_nik",
  },
  {
    header: "Jabatan",
    accessorKey: "jabatan",
  },
];
