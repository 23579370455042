import React, { useEffect, useState } from "react";
import { HeaderBreadcrumb } from "../../../../components/Dashboard/HeaderSubDashboard";
import { Link, useLocation } from "react-router-dom";
import { FaEye, FaPencil, FaTrashCan } from "react-icons/fa6";
import { ModalHapusComponent } from "../../../../components/Dashboard/data-barang/ModalHapusComponent";
import Api from "../../../../api/Api";

export const DetailBarang = () => {
  const params = useLocation().state;

  const [listKodeBarang, setListKodeBarang] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState({
    kode: null,
    nama_barang: null,
    kategori_barang: null,
    item_code: [],
  })

  let [modalHapusIsOpen, modalHapusSetIsOpen] = useState(false);
  function closeModalHapus() {
    modalHapusSetIsOpen(false);
  }
  function openModalHapus() {
    modalHapusSetIsOpen(true);
  }

  useEffect(() => {
    if (params) {
      setData({
        kode: params.kode,
        nama_barang: params.nama,
        kategori_barang: params.category_name,
      });

      fetchData(params.kode)
    }
  }, [params]);

  const fetchData = async (kode = "") => {
    try {
      setLoading(true);
      const result = await Api.fetchData(`/barang/detail/${kode}`);
      setListKodeBarang(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <HeaderBreadcrumb
          pathName="Data Barang"
          pathLink="/data-barang"
          pageName="Detail Barang"
        />
        <div className="flex flex-col mt-6 pb-4 rounded-lg bg-white">
          <div className="flex flex-row p-2 sm:p-4 md:p-6 justify-between border-b-2 border-flinneutral-black4 items-center">
            <span className="text-sm sm:text-base md:text-lg text-black">
              Detail Barang
            </span>
            <div className="flex flex-row gap-4">
              {/* HIDE FOR NOW */}
              {/* <div
                onClick={openModalHapus}
                className="flex flex-row text-flindanger-normal rounded-xl items-center gap-2 border border-flindanger-normal py-1.5 px-3 hover:bg-flindanger-bg cursor-pointer">
                <FaTrashCan />
                <span className="text-sm">Hapus</span>
              </div> */}
              <Link to="/data-barang/edit" state={params}>
                <div className="flex flex-row text-flinprimary-normal rounded-xl items-center gap-2 border border-flinprimary-normal py-1.5 px-3 hover:bg-flinprimary-bg cursor-pointer">
                  <FaPencil />
                  <span className="text-sm">Ubah</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col p-2 sm:p-4 md:p-6 gap-2 md:gap-4">
            <div className="flex flex-row border border-flinneutral-black5 bg-flinneutral-black2 p-2 md:p-4 rounded-lg">
              <div className="flex  flex-1 flex-col gap-1">
                <span className="text-xs md:text-sm">Nama Barang</span>
                <h5 className="text-sm md:text-base font-semibold">
                  {data.nama_barang}
                </h5>
              </div>
              <div className="flex flex-1 flex-col gap-1">
                <span className="text-xs md:text-sm">Kategori Barang</span>
                <h5 className="text-sm md:text-base font-semibold">
                  {data.kategori_barang}
                </h5>
              </div>
            </div>
            <div className="flex flex-row gap-2 md:gap-4">
              <span className="text-sm sm:text-base md:text-lg text-black">
                Daftar Kode Barang
              </span>
              <span className="text-xs md:text-sm py-1.5 px-3 bg-white border border-flinneutral-black5 rounded-lg md:rounded-xl">
                {listKodeBarang.length} Barang
              </span>
            </div>

            {/* TABLE */}
            <div className="flex flex-col border rounded-lg   bg-white">
              <div className="grid grid-cols-1 overflow-auto rounded-lg">
                <table className="w-full">
                  <thead className=" bg-flinneutral-black3 border-b border-flinneutral-black5">
                    <tr>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Kode Barang
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Status Barang
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left">
                        Nama Staf
                      </th>
                      <th className="px-3 md:px-6 py-3 text-sm font-semibold tracking-wide text-left w-28 ">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listKodeBarang.map((item, index) => (
                      <tr key={index} className="odd:bg-white even:bg-flinneutral-black2 border-y border-flinneutral-black4">
                        <td className="px-3 md:px-6 py-2 text-sm">{item.kode_barang}</td>
                        <td className="px-3 md:px-6 py-2 text-sm">
                          <span className={`bg-${item.status_pinjam === 0 ? 'green' : 'neutral'}-100 text-${item.status_pinjam === 0 ? 'green' : 'neutral'}-600 font-semibold text-sm md:text-base p-1.5 px-2 md:px-4 rounded-full`}>
                            {item.status_pinjam === 0 ? 'Dipakai' : 'Tidak Dipakai'}
                          </span>
                        </td>
                        <td className="px-3 md:px-6 py-2 text-sm">
                          {item.nama_peminjam}
                        </td>
                        <td className="px-3 md:px-6 py-2 text-sm">
                          <div className="flex flex-row gap-4 text-flinneutral-black7">
                            <span className="flex flex-row items-center gap-2 shadow-md bg-white text-flinprimary-normal font-semibold text-sm md:text-base p-1 px-2 md:px-4 rounded-lg">
                              <FaEye /> Detail
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalHapusComponent
        isOpen={modalHapusIsOpen}
        onClose={closeModalHapus}
      />
    </>
  );
};
