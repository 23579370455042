import apiCaller from './utils/apiCaller';

class Api {
  static async handleLogout() {
    localStorage.clear();
    window.location.href = "/login";
  }

  static async fetchData(path) {
    try {
      const response = await apiCaller.get(path);
      if (response.data.message === "Request API tidak ditemukan") {
        this.handleLogout();
        return
      }

      if (response.status === true) {
        return response.data.data;
      }

      return response.data;
    } catch (error) {
      if (401 === error.response.status) {
        this.handleLogout();
      }

      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("An error occurred during the request.");
      }
    }
  }

  static async postData(path, data, config = {}) {
    try {
      const response = await apiCaller.post(path, data, config);

      if (response.data.message === "Request API tidak ditemukan") {
        this.handleLogout();
        return;
      }

      if (response.status === true) {
        return response.data.data;
      }

      return response.data;
    } catch (error) {
      if (401 === error.response.status) {
        this.handleLogout();
      }

      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("An error occurred during the request.");
      }
    }
  }

  static async deleteData(path, rawData) {
    try {
      const response = await apiCaller.delete(path, { data: rawData });

      if (response.data.message === "Request API tidak ditemukan") {
        this.handleLogout();
        return;
      }

      if (response.status === true) {
        return response.data.data;
      }

      return response.data;
    } catch (error) {
      if (401 === error.response.status) {
        this.handleLogout();
      }

      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("An error occurred during the request.");
      }
    }
  }

  static async updateData(path, rawData) {
    try {
      const response = await apiCaller.put(path, rawData);

      if (response.data.message === "Request API tidak ditemukan") {
        this.handleLogout();
        return;
      }

      if (response.status === true) {
        return response.data.data;
      }

      return response.data;
    } catch (error) {
      if (401 === error.response.status) {
        this.handleLogout();
      }

      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("An error occurred during the request.");
      }
    }
  }
}

export default Api;