import React, { Fragment, useState, useEffect } from "react";
import defaultFotoProfil from "../../asset/image/dashboard/fotorofil.jpeg";
import {
  FaUserGear,
  FaArrowRightFromBracket,
  FaChevronDown,
} from "react-icons/fa6";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import classNames from "classname";
import { API_ENDPOINT_DOCUMENT } from "../../api/constants/api";

const EllipsisText = ({ text, maxLength }) => {
  const [truncatedText, setTruncatedText] = useState(text);

  useEffect(() => {
    if (text.length > maxLength) {
      setTruncatedText(text.slice(0, maxLength) + '...');
    } else {
      setTruncatedText(text);
    }
  }, [text, maxLength]);

  const ellipsisStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  };

  return <span style={ellipsisStyle}>{truncatedText}</span>;
};

export const ProfileLink = () => {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className=" focus:outline-none hover:bg-flinneutral-black2 rounded-lg">
          <div className="h-10 md:h-14 px-3 flex flex-row gap-2 p-2 items-center rounded-lg border border-flinneutral-black5">
            <div className="grid grid-content">
              <img
                src={(`${API_ENDPOINT_DOCUMENT}/${localStorage.getItem('foto_profile')}`)}
                alt="photo profile"
                className=" h-8 w-8 md:h-10 md:w-10 rounded-full"
                onError={(e) => {
                  e.target.src = defaultFotoProfil;
                }}
              />
            </div>
            <div className="flex text-left flex-col capitalize text-xs md:text-sm">
              <span className="font-semibold"><EllipsisText text={localStorage.getItem('name') ?? '-'} maxLength={16} /></span>
              <span><EllipsisText text={localStorage.getItem('role') ?? '-'} maxLength={16} /></span>
            </div>
            <FaChevronDown className="text-xl text-flinneutral-black6 ml-6" />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-40 rounded-lg shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Link to="/profil">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active && "bg-gray-100",
                    "text-flinneutral-black7 focus:bg-gray-200 cursor-pointer rounded-lg p-3 border-b border-flinneutral-black3 flex flex-row gap-2 items-center"
                  )}>
                  <FaUserGear className="text-xl" />
                  <span className="capitalize text-sm">profile</span>
                </div>
              )}
            </Menu.Item>
          </Link>
          <Link to="/login">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => {localStorage.clear()}}
                  className={classNames(
                    active && "bg-gray-100",
                    "focus:bg-gray-200 cursor-pointer rounded-lg p-3 border-b border-flinneutral-black3 flex flex-row gap-2 items-center text-flindanger-normal"
                  )}>
                  <FaArrowRightFromBracket className="text-xl" />
                  <span className="capitalize text-sm">logout</span>
                </div>
              )}
            </Menu.Item>
          </Link>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
