import React from "react";
import LogoKantor from "../../asset/icon/logo-kantor.png";
import { FaCircleXmark } from "react-icons/fa6";
import {
  DASHBOARD_SIDEBAR_LINK_SETTING,
  DASHBOARD_SIDEBAR_LINK_UTAMA,
} from "../../lib/constants/data/navigation";
import SidebarLink from "../../components/Dashboard/SidebarLink";
import { Footer } from "../footer/Footer";

export const Sidebar = ({ isOpen, onClose }) => {
  const handleLinkClick = () => {
    onClose();
  };
  return (
    <div className="relative">
      {isOpen && (
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-black opacity-50 z-10 md:hidden"
          onClick={onClose}></div>
      )}
      <div
        className={`bg-white text-[#8c8c8c] h-screen md:w-60 fixed top-0 left-0 overflow-y-hidden flex flex-col ${
          isOpen
            ? "transform translate-x-0  w-3/4"
            : "transform -translate-x-full "
        } md:translate-x-0 md:static`}
        style={{ zIndex: 1000 }}>
        {/* SIDEBAR HEADER */}
        <div className="flex flex-row justify-between items-center gap-2 py-6 pl-6 pr-6 md:pr-0 border-b border-flinneutral-black4  h-20">
          {/* TITLE HEADER */}
          <div className="flex flex-row items-center">
            <div className="grid content-center">
              <img
                src={LogoKantor}
                alt=""
                className=" max-w-[35px] object-containt"
              />
            </div>
            <p className="text-sm md:text-[11px] font-bold text-[#0270af] leading-tight pl-2">
              SEKRETARIAT DPRD KABUPATEN MURUNG RAYA
            </p>
          </div>
          {/* CLOSE BUTTON */}
          <div className="d-flex md:hidden" onClick={handleLinkClick}>
            <FaCircleXmark className="text-xl" />
          </div>
        </div>

        <div className="flex-1 py-6 flex flex-col gap-2 h-full overflow-y-scroll pb-10">
          {/* LINK MENU UTAMA */}
          <div className=" uppercase text-xs mx-6">
            <span>menu utama</span>
          </div>
          {DASHBOARD_SIDEBAR_LINK_UTAMA.map((item) => (
            <SidebarLink key={item.key} item={item} onClick={handleLinkClick} />
          ))}

          {/* LINK SETTING */}
          {/* <div className=" uppercase text-xs mx-6 mt-2">
            <span>setting</span>
          </div>
          {DASHBOARD_SIDEBAR_LINK_SETTING.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))} */}
        </div>
        <div className="flex md:hidden">
          <Footer />
        </div>
      </div>
    </div>
  );
};
